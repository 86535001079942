.account-setting-wrapper{
  min-height: 65vh;
  display: flex;
  /* align-items: center; */
  justify-content: center;
}
.account-setting-wrapper .account-setting-wrapper-inner {
  width: 75%;
  background-color: #fff;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.account-setting-wrapper .account-setting-wrapper-inner .tabs ul.tablist {
  list-style: none;
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 0;
  border-bottom: 3px solid lightgray;
}

.account-setting-wrapper .account-setting-wrapper-inner .tabs ul.tablist li {
  position: relative;
  padding: 10px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.account-setting-wrapper .account-setting-wrapper-inner .tabs ul.tablist li.active {
  font-weight: 600;
  color: var(--light-blue) !important;
}

.account-setting-wrapper .account-setting-wrapper-inner .tabs ul.tablist li.active::after {
  content: '';
  position: absolute;
  top: calc(100%);
  left: 0;
  height: 3px;
  width: 100%;
  background-color: #3498db !important;
  transition: all 0.3s ease-in-out;
}


.season_tabs {
  /* position: relative;
  min-height: 360px; */
  /* This part sucks */
  /* clear: both;
  margin-top: 66px; */
}

.season_tab {
  float: left;
  clear: both;
  width: 252px;
}

/* .season_tab label {
  background: #eee;
  padding: 10px 20px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  margin-left: -1px;
  font-size: 18px;
  vertical-align: middle;
  position: relative;
  left: 1px;
  width: 264px;
  font-weight: 700;
  height: 68px;
  display: table-cell;
  border-bottom: 1px solid lightgrey;
  text-align: left;
} */

.season_tab label span {
  padding: 0 5px;
}

.season_tab [type=radio] {
  display: none;
}

/* .season_content {
  position: absolute;
  top: 0;
  left: 265px;
  background: whitesmoke;
  right: 0;
  bottom: 0;
  opacity: 0;
  transition: all 0.55s;
  height: 75vh;
  width: 70%;
} */

/* .season_content .react-tel-input .country-list .country-name,
.season_content .react-tel-input .country-list .country .dial-code {
  margin-right: 6px;
  display: unset !important;
  padding: unset;
} */

.season_content span {
  display: flex;
  justify-content: center;
  text-align: start;
  flex-direction: column;
  align-items: center;
  padding: 0px 15px;

}

[type=radio]:checked~label {
  background: white;

  z-index: 2;
}

[type=radio]:checked~label~.season_content {
  z-index: 2;
  opacity: 1;
  transition: all 0.55s;
}
.plan-info-wrapper table{
  width: 100%;
}
.plan-info-wrapper .plan-features table thead tr th:first-child{
  width: 30%;
}
.plan-info-wrapper table thead tr th:first-child{text-align: start;}
.plan-info-wrapper .plan-features table thead tr th:not(:first-child){
  width: 35%;
}
.plan-info-wrapper table tbody tr td:first-child{
  text-align: start;
  text-transform: capitalize;
}
.plan-info-wrapper table thead tr{
  border-bottom:  1px solid #b2b2b2;
}
.plan-info-wrapper table tbody tr:not(:last-child)  {
  border-bottom: 1px solid #ddd;

}
.container.invoice-list .plan-info-wrapper table tr td {
  padding: 15px 10px ;
}
/* .container.invoice-list .plan-info-wrapper table tbody tr:last-child{
  border-bottom: unset;
} */

.plan-info-wrapper table tr :is(td,th){
  padding: 10px;
}
.plan-info-wrapper table tr :is(td,th):not(:last-child){
  text-align: left;
}
.plan-info-wrapper table tr :is(td,th):last-child{
  text-align: right;
}
.plan-info-wrapper table tr i{
  color: var(--light-blue);
}
.plan-info-wrapper .subscribed-plan-title{
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.plan-info-wrapper .subscribed-plan-title p{
  text-align: start;
  margin: 0;
}
.plan-info-wrapper .subscribed-plan-title .plan-title{
  font-weight: 600;
  padding-left: 10px ;
  /* font-size: 16px; */
  color: var(--light-blue);
}
.plan-info-wrapper .subscribed-plan-title .btn.btn-save{
  background-color: var(--buttonHover) ;
  color: #ffff ;
  border: 1px solid var(--buttonHover) ;
  padding: 8px 15px ;
  font-size: 14px ;
  font-weight: 600 ;
  border-radius: 0.25rem;
}
.plan-info-wrapper .subscribed-plan-title .btn.btn-sm{
  padding: 5px 10px!important;
}