.contract-wrapper {
    min-height: 100vh;
    background-color: #212124;
    width: 100%;
}

.contract-wrapper .contract-head-wrapper {
    background-color: #3a3a3f;
    width: 100%;
    padding: 8px 5px;
    position: sticky;
    top: 0;
}

.contract-wrapper .contract-head-wrapper .contract-header-inner-wrapper .brandLogo {
    position: absolute;
    top: 0px;
    left: 5px;
    transform: translate(15px, 0px);
}

.contract-wrapper .contract-head-wrapper .contract-header-inner-wrapper {
    display: flex;
    justify-content: center;
    position: relative;
}
.contract-wrapper .contract-body-wrapper {
    width: 100%;
    padding: 40px 0;
}

.contract-wrapper .contract-body-wrapper .contract-body-inner-wrapper {
    max-width: 786px;
    background-color: #fff;
    /* min-height: 100vh; */
    margin: 0 auto;
    text-align: left;
    font-size: 11px;
    font-family: Arial;
    padding: 30px;
}
.contract-wrapper .contract-body-wrapper .contract-body-inner-wrapper .export-container{display: flex;justify-content: flex-end;}
.contract-wrapper .contract-body-wrapper .contract-body-inner-wrapper .export-container button{display: flex; gap: 10px;justify-content: center;align-items: center;    padding: 5px 13px !important;}
.contract-wrapper .contract-body-wrapper .contract-body-inner-wrapper :is(h3, h4) {
    text-align: center;
    font-size: 14px;
    margin: 33px 0px 24px;
    font-weight: 600;
}

.contract-wrapper .contract-body-wrapper .contract-body-inner-wrapper table {
    font-size: 11px;
}

.contract-wrapper .contract-body-wrapper .contract-body-inner-wrapper .main-contract{
    min-height: 500px;
}
.contract-wrapper .contract-body-wrapper .contract-body-inner-wrapper .main-contract table input{border: 0; line-height: 1;border:unset;border-radius: 0.1rem;font-size: 14px; padding-bottom: 2px;}
.contract-wrapper .contract-body-wrapper .contract-body-inner-wrapper .main-contract table tbody tr td:has(input,img,.borderBottom){border-bottom: 1px solid #000;}
.contract-wrapper .contract-body-wrapper .contract-body-inner-wrapper .main-contract table tbody tr td:has(label){vertical-align: bottom;}
.contract-wrapper .contract-body-wrapper .contract-body-inner-wrapper .main-contract table tbody tr td input:is(#clientName, #clientTitle){
    box-shadow: 0 0 0 0.25rem rgba(13,110,253,.25);
}
.contract-wrapper .contract-body-wrapper .contract-body-inner-wrapper .main-contract table tbody tr td input:is(#clientName, #clientTitle):not(:empty){
    box-shadow: none;
}
.contract-wrapper .contract-body-wrapper .contract-body-inner-wrapper .agrement-action-wrapper {
    margin-top: 25px;
    text-align: right;
}

.contract-wrapper .contract-body-wrapper .contract-body-inner-wrapper .agrement-action-wrapper button {
    padding: 5px 10px !important;
}



.signature-wrapper {
    width: 500px;
    margin-top: 10px;
}

.signature-wrapper>* {
    transition: all 0.4s ease-in-out;
}

.signature-wrapper .signature-inner-wrapper {
    box-shadow: 3px 0 25px #ddd;
    width: 100%;
    height: 250px;
}
.signature-wrapper .signature-inner-wrapper canvas{width: 100%!important;}

.agreement-conditon-wrapper {
    text-align: left;
}

.agreement-conditon-wrapper .agreement-input-container {
    display: flex;
    align-items: center;
    gap: 15px;
}

.contract-body-inner-wrapper .contract-signature-img-wrapper {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    gap: 20px;
}

.contract-body-inner-wrapper .contract-signature-img-wrapper .signature-label h4 {
    margin-bottom: 0;
}

.contract-body-inner-wrapper .contract-signature-img-wrapper .contract-signature-img-inner-wrapper {
    border-bottom: 1px solid #454545;
    min-width: 150px;
    max-width: 200px;
    max-height: 110px;
}

.contract-body-inner-wrapper .contract-signature-img-wrapper .contract-signature-img-inner-wrapper img {
    width: 200px;
    height: auto;
    max-height: 100px;
    object-fit: contain;
}

.contract-body-wrapper :is(.signature-label h4, .contract-signature-img-wrapper .contract-signature-img-inner-wrapper img) {
    cursor: pointer;
}

.contract-signature-img-label-wrapper {
    display: flex;
    margin-top: 10px;
    gap: 10px;
    align-items: flex-end;
}

.agreement-submit {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 10px;
    gap: 10px;
}

.agreement-submit button[type='submit'] {
    margin: 0;
}

.agreement-submit button.btn-agre-close {
    color: #ffff !important;
    padding: 8px 15px !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    border-radius: 0.25rem !important;
}

.signature-actions button {
    width: 50%;
}

.signature-actions button:first-child {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.signature-actions button:nth-child(2) {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

.signature-specimen-wrapper img {
    width: 100%;
    height: auto;
}

.sign-btn-tabs-wrapper {
    display: flex;
}

.sign-btn-tabs-wrapper button.btn-tab-sign {
    outline: none;
    border: none;
    border-radius: unset;
    /* letter-spacing: 1px; */
    padding: 10px;
    border: 1px solid #d1d5db;
    background-color: #e5e7eb;
    font-weight: 600;
    color: #374151;
    width: 100%;
}

.sign-btn-tabs-wrapper button.btn-tab-sign:first-child {
    border-top-left-radius: 0.25rem;
}

.sign-btn-tabs-wrapper button.btn-tab-sign:last-child {
    border-top-right-radius: 0.25rem;
}

.sign-btn-tabs-wrapper button.btn-tab-sign.active {
    background-color: #baecf4 !important;
    border: 2px solid rgb(0, 164, 189) !important;
    color: #374151 !important;
}
.auto-sign-container{border: 1px solid #c3c3c3;border-radius: 0.1rem;}
.auto-sign-container .auto-sign-inner-container {
    display: flex;
    gap: 0.5rem;
    /* max-height: 300px; */
    height: 183px;
    overflow: auto;
    padding: 10px 0;
    flex-wrap: wrap;
    justify-content: center;
    scrollbar-width: 5px;
}

/* .auto-sign-inner-container::-webkit-scrollbar{} */
/* width */
.auto-sign-inner-container::-webkit-scrollbar {
    width: 5px;
}

/* Track */
.auto-sign-inner-container::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.auto-sign-inner-container::-webkit-scrollbar-thumb {
    background: #cacaca;
}

/* Handle on hover */
.auto-sign-inner-container::-webkit-scrollbar-thumb:hover {
    background: #555;
}
.sign-input-wrapper .sign-input-inner-wrapper{padding-bottom: 5px;} 
.signature-wrapper .sign-input-wrapper .sign-input-inner-wrapper {
    display: flex;
    align-items: center;
}
.tab-content .sign-input-wrapper .sign-input-inner-wrapper input{border-radius: 0.1rem;}

.auto-sign-container .auto-sign-inner-container .single-signature-container .single-signature {
    display: flex;
    /* flex-direction: column;
    align-items: center; */
    border: 1px solid #ddd;
    border-radius: 0.5rem;
    /* padding: 10px; */
    overflow: hidden;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.05);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    background-color: #fff;
}

.auto-sign-container .auto-sign-inner-container .single-signature:hover  {
    box-shadow: 0 0px 5px rgb(0 0 0 / 50%) !important;
}


.auto-sign-container .auto-sign-inner-container .single-signature-container .single-signature .single-signature-wrapper {
    width: 232px;
    padding: 10px;
    font-size: 20px;
    text-align: center;
    user-select: none;
}

.auto-sign-container .auto-sign-inner-container .single-signature-container .single-signature .single-signature-wrapper img {
    width: 100%;
}

.auto-sign-container .auto-sign-inner-container .single-signature-container{position: relative;}
.auto-sign-container .auto-sign-inner-container .single-signature-container input {    position: absolute;
    top: 0;
    visibility: hidden;
}
.auto-sign-container .auto-sign-inner-container .single-signature-container input[type='radio']:checked ~ label {
    background-color: #baecf4 !important;
    border: 2px solid rgb(0, 164, 189) !important;
    color: #374151 !important;

}

 .font-color-wrapper {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 10px 0;
}

 .font-color-wrapper .color-select-btn-container button {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    outline: unset;
    border: unset;
    transition: all 0.3s ease-in-out;
}

.font-color-wrapper .color-select-btn-container {
    display: flex;
    align-items: center;
    gap: 5px;
}
.font-color-wrapper .color-select-btn-container button i{color: #fff;}
.custom-contract-modal{
    width: 55%;
    background-color: #F0F1F3;
    /* height: 400px; */
    border-radius: 0.1rem;
    padding: 10px;
    top: 50%;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
}
.custom-contract-modal .custom-contract-modal-header{text-align: right;}
.custom-contract-modal .custom-contract-modal-header i{cursor: pointer;}
/* .custom-contract-modal i:hover{box-shadow: ;} */
.custom-contract-modal .custom-contract-modal-body .tab-container ul.tabs-pills{
    display: flex;
    list-style: none;
    gap: 30px;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
    width: 80%;
    margin: 0 auto;
    border-bottom: 1px solid #dddddd6b;
}
.custom-contract-modal .custom-contract-modal-body .tab-container ul.tabs-pills li{cursor: pointer;padding: 5px 13px;border-radius: 2px;font-weight: 600;user-select: none;transition: all 0.1s ease-in-out;}
.custom-contract-modal .custom-contract-modal-body .tab-container ul.tabs-pills li.active{background-color: #e6f7ff;color: var(--light-blue);}
.custom-contract-modal .custom-contract-modal-body .tab-container .tab-panels .tab-content{padding: 10px 0;}
.custom-contract-modal .custom-contract-modal-body .tab-container .tab-panels .tab-content .signatuter-canvas-container{
    max-width: 500px;
    /* background-color: #fff; */
    margin: 0 auto;
}
.custom-contract-modal .custom-contract-modal-body .tab-container .tab-panels .tab-content .signatuter-canvas-container canvas{background-color: #fff;}
.custom-contract-modal .custom-contract-modal-body .tab-container .tab-panels .tab-content button.btn-clear i{
    transform: rotate(360deg);
}
.custom-contract-modal .custom-contract-modal-body .tab-container .tab-panels .tab-content button.btn-clear:hover i{
    -webkit-animation:clear-spin 0.5s linear;
    -moz-animation:clear-spin 0.5s linear;
    animation:clear-spin 0.5s linear;
}
.custom-contract-modal .custom-contract-modal-body .tab-container .tab-panels .tab-content .action-container {
    padding: 5px 0;
    display: flex;
    justify-content: space-between;
}
.custom-contract-modal .custom-contract-modal-body .tab-container .tab-panels .tab-content .action-container .select-color-label-container span{font-weight: 600;}
.draw-canvas-wrapper{display: flex;justify-content: center;border: 1px solid #ddd;border-radius: 0.2rem;padding: 5px;}
.signatuter-canvas-container .save-container{text-align: end;margin: 10px 0;}  

/* rotate 360 key for refresh btn */
@-moz-keyframes clear-spin {  0%   { -webkit-transform: rotate(360deg); }
/* 25%  { -webkit-transform: rotate(180deg); } */
100% { -webkit-transform: rotate(0deg); } }
@-webkit-keyframes clear-spin {  0%   { -webkit-transform: rotate(360deg); }
/* 25%  { -webkit-transform: rotate(180deg); } */
100% { -webkit-transform: rotate(0deg); } }
@keyframes clear-spin {  0%   { -webkit-transform: rotate(360deg); }
/* 25%  { -webkit-transform: rotate(180deg); } */
100% { -webkit-transform: rotate(0deg); }}




@media(max-width: 768px){
    .custom-contract-modal{width: 90%;}
}
@media (min-width: 769px) and (max-width: 1024px){
    .custom-contract-modal{width: 70%;}
}
@media(min-width:1100px){
    .custom-contract-modal{width: 590px;}
}