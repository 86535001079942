.createitems-container ,.create-expense-wrapper{
  width: 60%;
    margin: 0 auto;
}
.Items-container {
 
  padding: 20px;
  display: flex;
  justify-content: center;
  border-radius: 5px;
  margin-top: 20px;
  flex-direction: column;
  width: 100%;
  margin: 20px auto;
}

.Items-container label {
  display: flex;
  font-weight: 500;
  align-items: baseline;
  margin-bottom: 5px;
  width: 130px;
}

.Items-container input,
.Items-container select
{
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 80%;
  margin-bottom: 10px;
}

.Items-container input[type="radio"] {
  margin-right: 5px;
  width: 15px !important;
}

.radio-btn {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.radio-btn p {
  font-weight: bold;
  margin-right: 10px;
}

.Items-container textarea {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 10px;
  width: 80%;
}

.Items-container button[type="submit"] {
  background-color: #333;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}


.group-label{
  display: flex;
  align-items: center;
  /* gap: 20px; */
}
.wrapper-items{
  display: flex;
  width: 100%;
  justify-content: space-around;
  background: #fff;
  padding: 25px;

}
.btn-button-submit{
  width: 100%;
  text-align: end;
}
.Items-container h3{
  text-align: start;
  padding: 12px 0;
  margin-bottom: 0px;
  font-size: 23px;
 background-color: #fff;
 border-top-left-radius: 10px!important;
 border-top-right-radius: 10px!important;  
}
.items-group-fields{
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
.radio-btn-flex{
  display: flex;
  align-items: center;
  gap: 10px;
}
.checbox-items{
  display: flex;
  align-items: center;
  
}
.checbox-items p{
  font-weight: 600;
}

.checbox-items input{
  margin-bottom: 6px !important;
  width: 13px;
}
.heading-card-2{
  background: #F7F7F7;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 10px;
  text-align: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
@media (max-width:1024px) {.Items-container .group-label{display: block;}}
@media (min-width:1024px) {.create-expense-wrapper ,.Items-container{width: 75%;}}