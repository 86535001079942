.error-page-container {
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    background-color: transparent;
}
.error-page-container .error-pages{
    margin: 0 auto;
    padding-top: 30px;
    text-shadow: 2px 2px #51B9E7;
}
    .error-page-container .clip .shadow {
        height: 180px;
    }
    .error-page-container .clip:nth-of-type(2) .shadow {
        width: 180px;
    }
    .error-page-container .clip:nth-of-type(1) .shadow, .error-page-container .clip:nth-of-type(3) .shadow {
        width: 250px;
    }
    .error-page-container .digit {
        width: 150px;
        height: 150px;
        line-height: 150px;
        font-size: 120px;
        font-weight: bold;
    }
    .error-page-container h2  {
        font-size: 32px;
    }
    .error-page-container .errorPage {
        margin-top: 2%;
        position: relative;
        height: 250px;
        padding-top: 40px;
    }
    .error-page-container .errorPage .clip {
        display: inline-block;
        transform: skew(-45deg);
    }
    .error-page-container .clip .shadow {
        overflow: hidden;
    }
    .error-page-container .clip:nth-of-type(2) .shadow {
        overflow: hidden;
        position: relative;
        box-shadow: inset 20px 0px 20px -15px rgba(150, 150, 150,0.8), 20px 0px 20px -15px rgba(150, 150,
          150,0.8);
    }

    .error-page-container .clip:nth-of-type(3) .shadow:after, .error-page-container .clip:nth-of-type(1) .shadow:after {
        content: "";
        position: absolute;
        right: -8px;
        bottom: 0px;
        z-index: 9999;
        height: 100%;
        width: 10px;
        background: linear-gradient(90deg, transparent, rgba(173,173,173, 0.8), transparent);
        border-radius: 50%;
    }
    .error-page-container .clip:nth-of-type(3) .shadow:after  {
        left: -8px;
    }
    .error-page-container .digit {
        position: relative;
        top: 8%;
        color: white;
       background: linear-gradient(90deg, hsla(216, 96%, 56%, 1) 0%, hsla(178, 64%, 65%, 1) 100%);
    background: -moz-linear-gradient(90deg, hsla(216, 96%, 56%, 1) 0%, hsla(178, 64%, 65%, 1) 100%);
    background: -webkit-linear-gradient(90deg, hsla(216, 96%, 56%, 1) 0%, hsla(178, 64%, 65%, 1) 100%);
        border-radius: 50%;
        display: inline-block;
        transform: skew(45deg);
    }
    .error-page-container .clip:nth-of-type(2) .digit {
        left: -4%;
    }
    .error-page-container .clip:nth-of-type(1) .digit {
        right: -20%;
    }
    .error-page-container .clip:nth-of-type(3) .digit {
        left: -20%;
    }
    .error-page-container h2 {
        color: #A2A2A2;
        font-weight: bold;
        padding-bottom: 20px;
    }
    .error-page-container .error-btn{
        display: flex;
        justify-content: center;
        gap: 20px;
    }
    .error-page-container .error-pages .error-btn .read-more {
        text-align: center;
    }
    .error-page-container .error-pages .error-btn .custom-btn {
        width: fit-content;
        height: 40px;
        color: #fff;
        border-radius: 5px;
        padding: 10px 25px;
        font-family: 'Lato', sans-serif;
        font-weight: 500;
        background: transparent;
        cursor: pointer;
        transition: all 0.3s ease;
        position: relative;
        display: inline-block;
        box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, .5),
            7px 7px 20px 0px rgba(0, 0, 0, .1),
            4px 4px 5px 0px rgba(0, 0, 0, .1);
        outline: none;
    }

    .error-page-container .error-pages .error-btn .read-more .btn-1 {
        background: linear-gradient(90deg, hsla(216, 96%, 56%, 1) 0%, hsla(178, 64%, 65%, 1) 100%);
        background: -moz-linear-gradient(90deg, hsla(216, 96%, 56%, 1) 0%, hsla(178, 64%, 65%, 1) 100%);
        background: -webkit-linear-gradient(90deg, hsla(216, 96%, 56%, 1) 0%, hsla(178, 64%, 65%, 1) 100%);
        border: none;
    }

    .error-page-container .error-pages .error-btn .read-more .btn-1:before {
        height: 0%;
        width: 2px;
    }

    .error-page-container .error-pages .error-btn .read-more-next {
        text-align: center;
    }
    .error-page-container .error-pages .error-btn .custom-btn {
        width: fit-content;
        height: 40px;
        color: #fff;
        border-radius: 5px;
        padding: 10px 15px;
        font-family: 'Lato', sans-serif;
        font-weight: 500;
        background: transparent;
        cursor: pointer;
        transition: all 0.3s ease;
        position: relative;
        display: inline-block;
        box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, .5),
            7px 7px 20px 0px rgba(0, 0, 0, .1),
            4px 4px 5px 0px rgba(0, 0, 0, .1);
        outline: none;
    }

    .error-page-container .error-pages .error-btn .read-more-next .btn-1 {
        background: linear-gradient(90deg, hsla(216, 96%, 56%, 1) 0%, hsla(178, 64%, 65%, 1) 100%);
        background: -moz-linear-gradient(90deg, hsla(216, 96%, 56%, 1) 0%, hsla(178, 64%, 65%, 1) 100%);
        background: -webkit-linear-gradient(90deg, hsla(216, 96%, 56%, 1) 0%, hsla(178, 64%, 65%, 1) 100%);
        border: none;
    }

    .error-page-container .error-pages .error-btn .read-more-next .btn-1:before {
        height: 0%;
        width: 2px;
    }


@media(max-width: 767px) {
.error-page-container .clip .shadow {
                height: 100px;
            }
.error-page-container .clip:nth-of-type(2) .shadow {
                width: 80px;
            }
.error-page-container .clip:nth-of-type(1) .shadow, .error-page-container .clip:nth-of-type(3) .shadow   {
                width: 100px;
            }
.error-page-container .digit {
                width: 80px;
                height: 80px;
                line-height: 80px;
                font-size: 52px;
            }
  .error-page-container h2  {
                font-size: 30px;
                font-weight: 800;
            }

  .error-page-container .errorPage
  {
    height: 150px;
    overflow: hidden;
  }
}
@media(max-width: 575px){
    .error-page-container .clip:nth-of-type(1) .digit {
        right: 9%;
    }
    .error-page-container .clip:nth-of-type(3) .digit {
        left: -9%;
    }
    .error-page-container .clip:nth-of-type(2) .digit {
        left: -6%;
    }
    .error-btn{
        display: flex;
        flex-direction: column;
        gap: 0 !important;
    }
}
