.galleryWrap {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    align-items: flex-start;
    /* justify-content: center;
    max-width: 620px; */
    margin: 0 auto;
  }
  
  .galleryWrap .single {
    width: 223px;
    border: 1px solid #ced4da;
    border-radius: 0.375rem;
    /* position: relative; */
    /* cursor: pointer; */
    text-align: left;
    cursor: pointer;
    box-shadow: 0 0px 2px rgb(0 0 0 / 20%);
  }
  .galleryWrap .single .details {
    word-break: break-word;
  }
  .galleryWrap .single .single-img{
    z-index: 3;
    width: 100%;
    position: relative;
    height: 150px;
    /* max-height: 200px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .galleryWrap .single .single-img img {
    width: 100%;
    height: auto;
    max-height: 100%;
  }
  
  .galleryWrap .single img:hover {
    transform: scale(1.02);
  }
  .sliderWrap  .sliderHead{
    width: 100%;
    text-align: center;
    color: #fff;
    font-weight: 600;
    padding: 20px;
  }
  .sliderWrap {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    flex-direction: column;
  }
  
  .sliderWrap i{
    font-size: 25px;
    color: white;
    font-weight: bolder;
    text-shadow: 0 0 10px black;
  }
  .sliderWrap .btnClose, .sliderWrap .btnPrev, .sliderWrap .btnNext {
    position: fixed;
    cursor: pointer;
    opacity: 0.6;
    color: #fff;
    z-index: 9999;
  }
  
  .btnNext:hover, .btnPrev:hover, .btnClose:hover {
    opacity: 1;
  }
  
  .sliderWrap .btnClose {
    top: 11%;
    right: 40px;
  }
  
  .sliderWrap .btnPrev {
    top: 50%;
    transform: translateY(-50%);
    left: 40px;
  }
  
  .sliderWrap .btnNext {
    top: 50%;
    transform: translateY(-50%);
    right: 40px;
  }
  
  .fullScreenImage {
    width: calc(100% - 40px);
    height: calc(80% - 40px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .fullScreenImage img {
    max-width: 100%;
    max-height: 100%;
    pointer-events: none;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */  
  }