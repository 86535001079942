.password-fields {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* background-color: white; */
    padding: 25px 0;
    width: 100%;
    /* min-height: 250px; */
    /* border-bottom-left-radius: 10px; */
    /* border-bottom-right-radius: 10px; */
    /* box-shadow: 0 0px 10px rgb(0 0 0 / 20%); */
  }
  .delete-account-wrapper .password-fields {
    padding: 15px;
  }
  .delete-account-wrapper .danger-icon-container i{font-size: 35px;}
  .passwordUpdate-container button[type='submit'] .submitbuttonInnerContainer {
    display: flex;
  }
  :is(.passwordUpdate-container,.profile-form) .form-feild{padding: 5px 0px;}
  :is(.passwordUpdate-container,.profile-form)  .form-feild .form-field-inner{
    position: relative;
    display: flex;
    /* align-items: center; */
    justify-content: center;flex-wrap: wrap;
  }
  :is(.passwordUpdate-container,.profile-form)  .form-feild .form-field-inner label{text-align: left;}
  .passwordUpdate-container .password-container{
    position: relative;
  }
  .passwordUpdate-container .password-container i{
    cursor: pointer;
  }
  .passwordUpdate-container .password-container .password-error-container{
    min-height: 40px;
    
  }
  /* .passwordUpdate-container .form-feild .form-field-inner .i */
  .passwordUpdate-container .password-container i.far , .passwordUpdate-container .form-feild .form-field-inner i.far{
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-55%, 84%);
    text-shadow: 0 0 2px black;
  }
  .password-fields .errorMsg{
    color: red;
    animation: shake 0.2s 2;
  }

  .password-fields input.shake {
    border: 2px solid red;
    animation: shake 0.2s 2;
  }

  @keyframes shake {
    25% {
      translate: 6px 0;
    }
    50% {
      translate: -6px 0;
    }
    75% {
      translate: -6px 0;
    }
  }
  .delete-account-wrapper{padding:20px;width: 62%;margin: 0 auto;}

  @media (max-width:768px) {
    .delete-account-wrapper{width: 100%;}
  }