.footer input {
  position: absolute;
  display: none
}

/* * {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
*/
.footer .menu {
    margin: 0 auto;
    position: fixed;
    right: 70px;
    bottom: 70px;
    z-index: 2;
  }
  
  .footer .btn {
      width: 45px;
      height: 45px;
      border-radius: 70px;
      position: absolute;
      overflow: hidden;
      cursor: pointer;
      /* color:var(--light-blue); */
      color: #fff;
  }
  
  .footer .material-icons.md-36 {
      font-size: 36px;
      /* color: #03A9F4;
      background: -webkit-linear-gradient(45deg, #14164c, #00acee 63%); */
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
  }
  
  .footer .btn {
      background: #212529;
      font-size: 15px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      border: 1px solid #fff;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
      /* box-shadow: 0 1px 21px rgba(0, 0, 0, 0.12), 0 1px 17px rgba(0, 0, 0, 0.24); */
      -webkit-transition: all 0.2s cubic-bezier(.25, .8, .25, 1);
      transition: all 0.2s cubic-bezier(.25, .8, .25, 1);
      box-shadow: 0.2rem 0 0.5rem rgba(0, 0, 0, 0.1);
  }
  .footer input#toggle:checked~#show-menu .btn svg path{ 
    /* stroke: #212529; */
    stroke-width: 3;
  }
  .footer input#toggle:checked~#show-menu .btn:hover svg path{ 
    stroke: #212529;
    stroke-width: 3;
  }
  .footer .btn span,
  .footer .btn a i{
    font-weight: 800;
  }
  
  .footer .btn:not(:first-child) {
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      z-index: -2;
  
      -webkit-transition: all 0.2s cubic-bezier(.87, -.41, .19, 1.44);
      transition: all 0.2s cubic-bezier(.87, -.41, .19, 1.44);
  }
  
  .footer .btn:nth-child(2) {
      top: 0px;
      /* -webkit-transition-delay: 0s;
      transition-delay: 0s; */
      /* background: -webkit-linear-gradient(45deg, #14164c, #00acee 63%); */
  }
  
  /* .footer .btn:nth-child(3) {
      top: 0px;
      left: 0px;
      -webkit-transition-delay: 0.1s;
      transition-delay: 0.1s
  } */
  
  .footer .btn:nth-child(4) {
      left: 0px;
      
      /* -webkit-transition-delay: 0.2s;
      ;
      transition-delay: 0.2s; */
      /* background: -webkit-linear-gradient(45deg, #14164c, #00acee 63%); */
  }
  .footer .btn a {
    /* color: -webkit-linear-gradient(45deg, #14164c, #00acee 63%); */
    text-decoration: none;
    color: #00acee;
    background: -webkit-linear-gradient(45deg, #14164c, #00acee 63%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .footer .btn:nth-child(5) {
      top: 0px;
      left: 0px;
      -webkit-transition-delay: 0.3s;
      transition-delay: 0.3s
  }
  
  .footer .btn:nth-child(6) {
      top: 0px;
      -webkit-transition-delay: 0.4s;
      transition-delay: 0.4s
  }
  
  .footer .btn:nth-child(7) {
      top: 0px;
      left: 0px;
      -webkit-transition-delay: 0.5s;
      transition-delay: 0.5s
  }
  
  /* .footer .btn:nth-child(3) {
      left: 0px;
      -webkit-transition-delay: 0.6s;
      transition-delay: 0.6s
  } */
  
  .footer .btn:nth-child(3) {
      top: 0px;
      left: 0px;
      -webkit-transition-delay: 0.2s;
      transition-delay: 0.2s
  }
  
  .footer input#toggle:checked~#show-menu .btn:nth-child(2) {
    top: -48px;
    /* background: -webkit-linear-gradient(45deg, #14164c, #00acee 63%); */
    /* -webkit-background-clip: text; */
    /* -webkit-text-fill-color: transparent; */
      opacity: 1;
      /* -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)"; */
      background-color: #fff;
  }
  
  /* .footer input#toggle:checked~#show-menu .btn:nth-child(3) {
      top: -100px;
      left: 100px;
      opacity: 1;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  } */
  
  .footer input#toggle:checked~#show-menu .btn:nth-child(4) {
      left: 150px;
      opacity: 1;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  }
  
  .footer input#toggle:checked~#show-menu .btn:nth-child(5) {
      top: 100px;
      left: 100px;
      opacity: 1;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  }
  
  .footer input#toggle:checked~#show-menu .btn:nth-child(6) {
      top: 150px;
      opacity: 1;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  }
  
  .footer input#toggle:checked~#show-menu .btn:nth-child(7) {
      top: 100px;
      left: -100px;
      opacity: 1;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  }
  
  .footer input#toggle:checked~#show-menu .btn:nth-child(8) {
      left: -150px;
      opacity: 1;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  }
  
  .footer input#toggle:checked~#show-menu .btn:nth-child(3) {
    top: -21px;
    left: -56px;
    /* background: -webkit-linear-gradient(45deg, #14164c, #00acee 63%); */
    /* -webkit-background-clip: text; */
    /* -webkit-text-fill-color: transparent; */
      opacity: 1;
      /* -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)"; */
      background-color: #fff;
  }
  
  .footer .menuBtn,
  .footer .closeBtn {
      position: absolute;
      transition: all 0.3s ease;
      font-size: 22px;
  }
  
  .footer .closeBtn {
      transform: translateY(50px);
      opacity: 0;
  }
  
  .footer input#toggle:checked~#show-menu .btn .menuBtn {
      transform: translateY(-50px);
      opacity: 0;
  }
  
  .footer input#toggle:checked~#show-menu .btn .closeBtn {
      transform: translateY(0px);
      opacity: 1;
  }
  .animated:hover{
    -webkit-animation: tada 2s linear ;
  animation: tada 2s linear ;
  }
  


  @-webkit-keyframes tada {
    0% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  
    10%, 20% {
      -webkit-transform: scale(.9) rotate(-8deg);
      transform: scale(.9) rotate(-8deg);
    }
  
    30%, 50%, 70% {
      -webkit-transform: scale(1.3) rotate(8deg);
      transform: scale(1.3) rotate(8deg);
    }
  
    40%, 60% {
      -webkit-transform: scale(1.3) rotate(-8deg);
      transform: scale(1.3) rotate(-8deg);
    }
  
    80% {
      -webkit-transform: scale(1) rotate(0);
      transform: scale(1) rotate(0);
    }
    100%{
      -webkit-transform: scale(1) rotate(0);
      transform: scale(1) rotate(0);
    }
  }
  
  @keyframes tada {
    0% {
      -webkit-transform: scale(1);
      -ms-transform: scale(1);
      transform: scale(1);
    }
  
    10%, 20% {
      -webkit-transform: scale(.9) rotate(-8deg);
      -ms-transform: scale(.9) rotate(-8deg);
      transform: scale(.9) rotate(-8deg);
    }
  
    30%, 50%, 70% {
      -webkit-transform: scale(1.3) rotate(8deg);
      -ms-transform: scale(1.3) rotate(8deg);
      transform: scale(1.3) rotate(8deg);
    }
  
    40%, 60% {
      -webkit-transform: scale(1.3) rotate(-8deg);
      -ms-transform: scale(1.3) rotate(-8deg);
      transform: scale(1.3) rotate(-8deg);
    }
  
    80% {
      -webkit-transform: scale(1) rotate(0);
      -ms-transform: scale(1) rotate(0);
      transform: scale(1) rotate(0);
    }
    100%{
      -webkit-transform: scale(1) rotate(0);
      -ms-transform: scale(1) rotate(0);
      transform: scale(1) rotate(0);
    }
  }