
.clients-container{
  width: 70%;
}
.Clients-form-container {
  /* max-width: 100%; */
  margin: 0 auto;
  /* padding: 20px; */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  /* background-color: #f5f5f5; */
  border-radius: 10px;
}

.heading-card {
  text-align: center;
 width: 100%;
 padding: 10px;
}

.heading-card h3 {
  font-size: 24px;
  color: #333;
  font-weight: 500;
  margin: 7px 0;
}

.wrapper-items {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100% !important;
  padding: 20px !important;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 0 0 5px 5px;
}

.col-6 {
  width: 48%;
}

.group-label {
  margin: 10px;
  text-align: start;
}
.clients-container .group-label{
  display: block  ;
}
.group-label label {
  display: block;
  /* width: 100%; */
    font-size: 15px;
    margin-bottom: 5px;
    color: #555;
    font-weight: 700;
    /* width: 30%; */
}

.group-label input,
.group-label textarea,
.group-label select {
  width: 100%;
  padding: 7px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
  color: #333;
}
.group-label input[type='checkbox']{
  width: fit-content;
}
.group-label select{
  width: 100%;
}
.group-label.select-custom select{
  width: 100%;
}
.group-label select {
  cursor: pointer;
}

.btn-button-submit {
  /* margin-top: 20px; */
  text-align: end;
}

.btn-button-submit button {
  padding: 10px 20px;
  background-color: #333;
  color: #fff;
  font-size: 18px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-button-submit button:hover {
  background-color: #555;
}



.heading-card{
  background: #F7F7F7;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 10px;
  text-align: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}


@media (min-width:1200px) {
  .clients-container {
    width: 70%!important;
}
}
