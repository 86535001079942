.dash-header-wrapper{
    top: 0;
    z-index: 100;
    position: sticky!important;
    top: 0;
}
.dash-header-wrapper .dashboard-header{
    background-color: #F9F9F9;
    padding: 20px 50px 20px 60px !important;
    border-bottom: 1px solid #cecccc7d;
    justify-content: space-between!important;
    box-shadow: 4px 0px 10px rgb(0 0 0 / 30%);
    
}
.dash-header-wrapper .dashboard-header .brand-logo .brand-logo-title{
    margin-bottom: 0;
    font-size: 24px;
}
.dash-header-wrapper .dashboard-header a{
    text-decoration: none;
    color: #979797;
    transition: all 0.15s ease-in-out;
}
.dash-header-wrapper .dashboard-header a:hover{
    color: var(--themeColor);
}
.dash-header-wrapper .dashboard-header a:hover svg path{
    stroke: var(--themeColor);
}
.dash-header-wrapper .dashboard-header a .nav-profile{
    display: flex;
    align-items: flex-end;
    font-weight: 600;
}
.dash-header-wrapper .dashboard-header a .nav-profile span{
    padding-left: 5px;
    font-size: 16px;
    /* color: var(--themeColor); */
    color: #454545;
    line-height: 1;
}

.dash-header-wrapper .alert-dismissible .btn-close {
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    font-size: 14px;
}
.dash-header-wrapper .alert{
    border-radius: 5px;
    margin: 10px auto;
    text-align: start;
}
.dash-header-wrapper .alert button:focus{
    box-shadow: none;
}

.dash-header-wrapper .alert p{
    margin: 0;
}

.dash-header-wrapper .alert a{
    color: var(--themeColor);
}
.dash-header-wrapper nav.dashboard-header  .btn-upgrade-plan{
    /* background: #fff; */
    font-size: 14px;
    color: #374151;
    padding: 4px 12px;
    /* border: 1px solid rgb(151, 151, 151); */
    border-radius: 4px;
    -webkit-font-smoothing: antialiased;
    font-weight: 600;
    display: inline-block;
    overflow: hidden;
    text-decoration: none;
    min-width: 51px;
    cursor: pointer;
    -webkit-transition: ease-in 100ms;
    -o-transition: ease-in 100ms;
    transition: ease-in 100ms;
    vertical-align: middle;
    margin-right: 10px;
    background-image: linear-gradient(45deg, #00acee, #5655d9);
    border: 1px solid #00acee !important;
    color: #fff;
  }
  .dash-header-wrapper nav.dashboard-header .btn-upgrade-plan:hover{
    background: #fff;
    border-color: var(--themeColor);
    color: var(--themeColor);
  }
  .dash-header-wrapper nav.dashboard-header ul.navbar-nav{
    align-items: center;
    flex-direction: row;
    
  } 