.modal {
    /* position: fixed; */
    inset: 0; 
    background-color: rgba(0, 0, 0, 0.6);
    display: block!important;
    /* flex-direction: column;
    align-items: center; */
    /* justify-content: center; */
    transition: all 0.3s ease-in-out;
    /* overflow: hidden; */
    z-index: 9999;
    /* padding: 160px 20px 20px; */
    /* justify-content: center; */
  }
  /* .modal::-webkit-scrollbar{
    width: 5px;
  } */
  /* width */
.modal::-webkit-scrollbar {
  width: 8px;
}

/* Track */
.modal::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.modal::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.modal::-webkit-scrollbar-thumb:hover {
  background: #abaaee;
}
  .modal-container{
    min-width: 500px;
    max-width: 600px;
    /* min-height: 300px; */
    background-color: white;
    border-radius: 10px;
    padding: 10px;
    border-radius: 5px;
    /* max-height: 90vh; */
    /* overflow: hidden; */
    /* margin-top: 40vh;
    margin-bottom: 30px; */
    margin: 1.75rem auto;
  }
  .modal-Header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #cdc5c5;
  }
  .modal .modal-container .modal-body {
    /* overflow-y: scroll;
    max-height: 500px; */
    scroll-behavior: smooth;
    scrollbar-color: #e5e7eb;
    scrollbar-width: thin;
  }

  .modal .modal-container .modal-body::-webkit-scrollbar {
    width: 5px;
    /* display: none; */
  }
  .modal .modal-container:hover .modal-body::-webkit-scrollbar {
    display: block;
  }
  /* Track */
  .modal .modal-container .modal-body::-webkit-scrollbar-track {
    background: #e5e7eb;
   
  }
  
  /* Handle */
  .modal .modal-container .modal-body::-webkit-scrollbar-thumb {
    background: #bab2b2;display: none;    border-radius: 10px;
  }
  .modal .modal-container:hover .modal-body::-webkit-scrollbar-thumb {
    display: block;
  }
  
  /* Handle on hover */
  .modal .modal-container .modal-body::-webkit-scrollbar-thumb:hover {
    background: #555;
    display: block;
  }


  .modal-content {
    width: 70%;
    height: 70%;
    background-color: #282c34;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
  }
  .popup {
    width: 350px;
    padding: 15px;
    left: 50%;
    /* margin-left: 33.333333%; */
    border: 1px solid #ccc;
    border-radius: 5px;
    background: white;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    /* box-shadow: 5px 5px 5px #000; */
    z-index: 10001;
    text-align: center;
  }
  .popup button {
    font-weight: 600;
    /* height: 40px; */
    border-radius: 0.25rem;
  }
  .popup i{
    font-size: xxx-large;
    /* position: absolute;
    background-color: #fff;
    top: 0;
    left: 50%;
    transform: translate(-51%, -61%);
    padding: 5px 11px 0;
    border-radius: 13%; */
  }
  @media (max-width: 400px) {
    .popup{width: 95%;}
  }
  @media (max-width:767px) {
    .modal-container {
      min-width: 200px;
      width: 95%;
  }
  }

  @media (min-width:1024px) {
    .popup{
      width: 560px;
    }
  }