@font-face {
    font-family: 'Brittney';
    src: url('./fonts/BrittanySignature.ttf');
    /* font-display: block; */
  }
  
  @font-face {
    font-family: 'Alfie';
    src: url('./fonts/AlfieCasual.ttf');
    /* font-display: block; */
  }
  
  @font-face {
    font-family: 'Sagrantino';
    src: url('./fonts/SagrantinoW01Regular.ttf');
    /* font-display: block; */
  }
  
  @font-face {
    font-family: 'Poppins';
    src: url('./fonts/Poppins-Medium.ttf');
    /* font-display: block; */
  }
  
  @font-face {
    font-family: 'AguafinaScript';
    src: url('./fonts/AguafinaScript-Regular.ttf');
    /* font-display: block; */
  }
  
  @font-face {
    font-family: 'AlexBrush';
    src: url('./fonts/AlexBrush-Regular.ttf');
    /* font-display: block; */
  }
  
  @font-face {
    font-family: 'AnnieUseYourTelescope';
    src: url('./fonts/AnnieUseYourTelescope-Regular.ttf');
    /* font-display: block; */
  }
  
  @font-face {
    font-family: 'Bilbo';
    src: url('./fonts/Bilbo-Regular.ttf');
    /* font-display: block; */
  }
  
  @font-face {
    font-family: 'BilboSwashCaps';
    src: url('./fonts/BilboSwashCaps-Regular.ttf');
    /* font-display: block; */
  }
  
  @font-face {
    font-family: 'ButterflyKids';
    src: url('./fonts/ButterflyKids-Regular.ttf');
    /* font-display: block; */
  }
  
  @font-face {
    font-family: 'Calligraffitti';
    src: url('./fonts/Calligraffitti-Regular.ttf');
    /* font-display: block; */
  }
  
  @font-face {
    font-family: 'ClickerScript';
    src: url('./fonts/ClickerScript-Regular.ttf');
    /* font-display: block; */
  }
  
  @font-face {
    font-family: 'Courgette';
    src: url('./fonts/Courgette-Regular.ttf');
    /* font-display: block; */
  }
  
  @font-face {
    font-family: 'CraftyGirls';
    src: url('./fonts/CraftyGirls.ttf');
    /* font-display: block; */
  }
  
  @font-face {
    font-family: 'DeliusSwashCaps';
    src: url('./fonts/DeliusSwashCaps-Regular.ttf') format("truetype");
    /* font-display: block; */
  }
  
  @font-face {
    font-family: 'Devonshire';
    src: url('./fonts/Devonshire-Regular.ttf');
    /* font-display: block; */
  }
  
  @font-face {
    font-family: 'Felipa';
    src: url('./fonts/Felipa-Regular.ttf');
    /* font-display: block; */
  }
  
  @font-face {
    font-family: 'GiveYouGlory';
    src: url('./fonts/GiveYouGlory.ttf');
    /* font-display: block; */
  }
  
  @font-face {
    font-family: 'GrandHotel';
    src: url('./fonts/GrandHotel-Regular.ttf');
    /* font-display: block; */
  }
  
  @font-face {
    font-family: 'GreatVibes';
    src: url('./fonts/GreatVibes-Regular.ttf');
    /* font-display: block; */
  }
  
  @font-face {
    font-family: 'JimNightshade';
    src: url('./fonts/JimNightshade-Regular.ttf');
    /* font-display: block; */
  }
  
  @font-face {
    font-family: 'Kristi';
    src: url('./fonts/Kristi.ttf');
    /* font-display: block; */
  }
  
  @font-face {
    font-family: 'LaBelleAurore';
    src: url('./fonts/LaBelleAurore.ttf');
    /* font-display: block; */
  }
  
  @font-face {
    font-family: 'LeagueScript';
    src: url('./fonts/LeagueScript.ttf');
    /* font-display: block; */
  }
  
  @font-face {
    font-family: 'LoversQuarrel';
    src: url('./fonts/LoversQuarrel-Regular.ttf');
    /* font-display: block; */
  }
  
  @font-face {
    font-family: 'Macondo';
    src: url('./fonts/Macondo-Regular.ttf');
    /* font-display: block; */
  }
  
  @font-face {
    font-family: 'Meddon';
    src: url('./fonts/Meddon.ttf');
    /* font-display: block; */
  }
  
  @font-face {
    font-family: 'Megrim';
    src: url('./fonts/Megrim.ttf');
    /* font-display: block; */
  }
  
  @font-face {
    font-family: 'MonsieurLaDoulaise';
    src: url('./fonts/MonsieurLaDoulaise-Regular.ttf');
    /* font-display: block; */
  }
  
  @font-face {
    font-family: 'MrsSaintDelafield';
    src: url('./fonts/MrsSaintDelafield-Regular.ttf');
    /* font-display: block; */
  }
  
  @font-face {
    font-family: 'Niconne';
    src: url('./fonts/Niconne-Regular.ttf');
    /* font-display: block; */
  }
  
  @font-face {
    font-family: 'OleoScriptSwashCaps';
    src: url('./fonts/OleoScriptSwashCaps-Regular.ttf');
    /* font-display: block; */
  }
  
  @font-face {
    font-family: 'Poppins';
    src: url('./fonts/Poppins-Regular.ttf');
    /* font-display: block; */
  }
  
  @font-face {
    font-family: 'Qwigley';
    src: url('./fonts/Qwigley-Regular.ttf');
    /* font-display: block; */
  }
  
  @font-face {
    font-family: 'ReenieBeanie';
    src: url('./fonts/ReenieBeanie.ttf');
    /* font-display: block; */
  }
  
  @font-face {
    font-family: 'RugeBoogie';
    src: url('./fonts/RugeBoogie-Regular.ttf');
    /* font-display: block; */
  }
  
  @font-face {
    font-family: 'SedgwickAve';
    src: url('./fonts/SedgwickAve-Regular.ttf');
    /* font-display: block; */
  }
  
  @font-face {
    font-family: 'Sevillana';
    src: url('./fonts/Sevillana-Regular.ttf');
    /* font-display: block; */
  }
  
  @font-face {
    font-family: 'ShadowsIntoLight';
    src: url('./fonts/ShadowsIntoLight.ttf');
    /* font-display: block; */
  }
  
  @font-face {
    font-family: 'Stalemate';
    src: url('./fonts/Stalemate-Regular.ttf');
    /* font-display: block; */
  }
  
  @font-face {
    font-family: 'UnifrakturMaguntia';
    src: url('./fonts/UnifrakturMaguntia-Book.ttf');
    /* font-display: block; */
  }
  
  @font-face {
    font-family: 'Vibur';
    src: url('./fonts/Vibur-Regular.ttf');
    /* font-display: block; */
  }
  
  @font-face {
    font-family: 'WalterTurncoat';
    src: url('./fonts/WalterTurncoat.ttf');
    /* font-display: block; */
  }
  
  @font-face {
    font-family: 'Zeyada';
    src: url('./fonts/Zeyada.ttf');
    /* font-display: block; */
  }