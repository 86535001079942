.error-container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.error-container a {
    display: inline-block;
    text-decoration: underline;
    margin: 2rem 0;
}
