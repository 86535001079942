.demo-list{
    list-style:  none;
    padding-left: 5px;
    margin-block: 5px;
}
.demo-list i{
    color: var(--light-blue);
}
.rounded-lg {
    border-radius: 1rem !important;
}

.text-small {
    font-size: 0.9rem !important;
}
.plans-wrapper div:nth-child(2) .plan-container {
    padding-block: 3rem !important;
    min-height: 540px;
    transform: scaleY(1.03);
}
.plans-wrapper .plan-container{
    min-height: 497px;
    overflow: hidden;
    position: relative;
}
.plans-wrapper .plan-container .custom-price-separator {
    width: 5rem;
    height: 6px;
    border-radius: 1rem;
    /* background-color: var(--themeColor); */
    background-color: var(--light-blue);
}
.plans-wrapper .plan-container .custom-price-separator.activated{
    background-color: var(--themeColor);
}
.plans-wrapper .plan-container ul i.fa{
    color: var(--light-blue);
}
.plans-wrapper .plan-container ul i.fa.activated{
    color: var(--themeColor);
}
.plans-wrapper .plan-container .btn-subscribe:is(.activated,.activated:hover){
    background-color: var(--themeColor);
    color: #fff;
    border: none;
    box-shadow: unset!important;
}

.text-uppercase {
    letter-spacing: 0.2em;
}

.plans-wrapper .plan-container .price .save-ammount {
    font-size: 14px!important;
    color: var(--light-blue);
}
.plan-wrapper ul i.text-primary{
    color: var(--light-blue)!important;
}
.btn-subscribe{
    height: 40px;
    min-width: 101px;
}


.pricing-switcher {
    text-align: center;
}

.pricing-switcher .fieldset {
    display: inline-block;
    position: relative;
    padding: 2px;
    border-radius: 50em;
    border: 2px solid var(--themeColor);
}

.pricing-switcher input[type="radio"] {
    position: absolute;
    opacity: 0;
}

.pricing-switcher label {
    position: relative;
    z-index: 1;
    display: inline-block;
    float: left;
    width: 90px;
    height: 40px;
    line-height: 40px;
    cursor: pointer;
    font-size: 1.2rem;
    color: #000;
    background: unset !important;
    font-weight: 600;
}

.pricing-switcher .switch {
    position: absolute;
    top: 2px;
    left: 2px;
    height: 40px;
    width: 90px;
    background-color: var(--light-blue);
    border-radius: 50em;
    -webkit-transition: -webkit-transform 0.5s;
    -moz-transition: -moz-transform 0.5s;
    transition: transform 0.5s;
}

.pricing-switcher input[type="radio"]:checked+label {
    color: #ffffff;
}

.pricing-switcher input[type="radio"]:checked+label+.switch,
.pricing-switcher input[type="radio"]:checked+label:nth-of-type(n)+.switch {
    -webkit-transform: translateX(90px);
    -moz-transform: translateX(90px);
    -ms-transform: translateX(90px);
    -o-transform: translateX(90px);
    transform: translateX(90px);
}

.no-js .pricing-switcher {
    display: none;
}
.offscreen {
    position: absolute;
    left: -9999px;
}
.errmsg {
    /* background-color: lightpink; */
    color: firebrick;
    font-weight: bold;
    padding: 0.2rem;
    margin-bottom: 0.2rem;
}
.subscribed-card {
    background: white;
    padding: 60px 50px;
    border-radius: 4px;
    box-shadow: 0 2px 3px #C8D0D8;
    display: inline-block;
    margin: 0 auto;
  }

  .subscribed-card h1 {
    color: #88B04B;
    font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
    font-weight: 900;
    font-size: 30px;
    margin-bottom: 10px;
  }
  .subscribed-card p {
    color: #404F5E;
    font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
    font-size:20px;
    margin: 0;
  }
  .subscribed-card  .checkmark {
    color: #9ABC66;
    font-size: 100px;
    line-height: 200px;
    margin-left:-15px;
  }
  .subscribed-card button.btn-success{
    background-color: #9ABC66;
    border-radius: 4px;
    border-color: #9ABC66;
  }
.plan-activated-tag{
    position: absolute;
    top: 25px;
    right: -35px;
    transform: rotate(45deg);
    padding: 1px 40px;
    background-color: var(--themeColor);
    color: #fff;
    font-weight: 600;
    user-select: none;
  }
  .plan-activated-border{
    border: 2px solid var(--themeColor)!important;
  }

  .MuiListItem-root.custom-subscribe-modal-li{
    padding: 0;
  }