/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/
html{
  scroll-behavior: smooth;
}
@font-face {
  font-family: Poppins;
  src: url(./assets/fonts/Poppins-Light.ttf);
}
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box!important;
}
/* body::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
	border-radius: 10px;
}

body::-webkit-scrollbar
{
	width: 10px;
	background-color: #F5F5F5;
}

body::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	background-image: linear-gradient(120deg, var(--light-blue), #1c1e5e);
} */
body{
  background-color: #F0F2F5;
}

.App {
  background-repeat: repeat;
  font-family: "Poppins" !important;
  min-height: 100vh;
  background-repeat: repeat;
  /* background-size: cover; */
  /* background-color: #eff4f7; */
  background-color: #F0F2F5;
}
.dashboard-layout-wrapper{
  position: relative;
  left: 80px;
  width: calc(100% - 80px);
  min-height: 100vh;
}

/* --notification-bg-info: #2daac4 ;
  --notification-bg-success: #25c47a;
  --notification-bg-danger: #e04453;
  --notification-bg-primary: #326ec5;
   --notification-bg-info: #54B4D3;
  --notification-bg-success: #14A44D;
  --notification-bg-danger: #DC4C64;
  --notification-bg-primary: #3B71CA; */



/* 
As decided by sir Usman
news      -> info     bg #add8e6  txt #00008b
update    -> success  bg #d1f0d1  txt #006400
alarming  -> danger   bg #ffffe0  txt #8b0000
normal    -> primary  


success bg  :#25AE88
   

*/
.text-custom-success{color:var(--light-blue)}
:root {
  --dark-blue: #6497ba;
  --light-blue: #24b6f7;
  --themeColor: #14164c;
  --themeBtnColor: #14164c;
  --buttonHover: #00acee;
  --notification-bg-info:  #add8e6;
  --notification-txt-info: #00008b;
  --notification-bg-success: #d1f0d1;
  --notification-txt-success: #006400;
  --notification-bg-danger: #ffffe0 ;
  --notification-txt-danger: #8b0000;
  --notification-bg-primary: #3B71CA;
  --notification-txt-primary: #fff;
  --notification-txt-warning: #bc9e00;
  --notification-bg-warning: #feffbf;
  --text-danger : #ff031c;
  /* --grey-99: #999b9e;
  --grey-32: #32373c;
  --greyc-32: #32373ccc;
  --greyc9-32: #32373c99;
  --greyc80-32: #32373c80;
  --blk-08: #082836;
  --clr-danger: #ff0000;
  --clr-warning: #ffc043;
  --clr-success: #4bbb57;
  --scrollbar-light-blue: #c3d4df;
  --grey-ee: #eeeeee;
  --grey-d6: #d6d7d8;
  --blue-d9: #d9e9f0;
  --grey-b7: #b7b7b7;
  --blue-10: #105d8d40;
  --blue-90: #90b4cd;
  --red-90: #ff0000;
  --red-90-light: #ff0000c4;
  --white-fa: #fafafa;
  --white-fff: #ffffff;
  --clr-green: #4bbb57;
  --clr-green-light: #4bbb56bb;
  --width-mapList: 462px;
  --width-collapseBar: -462px; */
  --login-achor-text: #14164c;
}

body {
  /* background-color: #f3f4f6 !important; */
  color: #454545 !important;
  font-size: 0.9rem !important;
  background-color: rgb(228 232 239) !important;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.text-danger {
  --bs-text-opacity: 1;
  color: #ff031c !important;
}
.btn {
  box-shadow: 0 0px 3px rgb(0 0 0 / 5%);
  padding: 8px 15px !important;
}
.btn:hover {
  /* border: 1px solid #d1d5db !important; */
  box-shadow: 0 0px 5px rgb(0 0 0 / 50%) !important;
}
.btn-primary {
  background-color: var(--themeBtnColor) !important;
  color: #ffff !important;
  border: 1px solid var(--themeBtnColor) !important;
  padding: 8px 15px !important;
  font-size: 14px !important;
  border-radius: 0.25rem!important;
}
/* btn-primary hover */
.btn-primary:hover {
  background-color: var(--buttonHover) !important;
  border: 1px solid var(--buttonHover) !important;
}

button.btn-save, nav a.btn-save {
  background-color: var(--buttonHover) !important;
  color: #ffff !important;
  border: 1px solid var(--buttonHover) !important;
  padding: 9px 15px !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  border-radius: 0.25rem!important;
}
.listing-button-edits{
  color: var(--themeColor)!important;
}
.listing-button-simple{
  background-color: rgb(229, 231, 235)!important;
  padding: 2px 5px !important;
  /* box-shadow: 1px 2px 5px #ddd, 0 2px 0.5px rgba(229, 234, 242, 0.4); */
  background-color: #fefefe!important;
  transition: all 0.2s ease-in-out;
  box-shadow: 2px 3px 7px rgba(0, 0, 0 , 0.2);
}
/* .btn-dropdown-menu-custom:active{
  border: none;
  
} */
.btn-dropdown-menu-custom{
  padding: 3px!important;
  font-size: 1.125rem!important;
  color: rgba(0, 0, 0, 0.54)!important;
  padding: 12px!important;
  overflow: visible!important;
  /* font-size: 1.5rem!important; */
  text-align: center!important;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms!important;
  border-radius: 50%!important;
  /* color: inherit; */
  border: 0!important;
  cursor: pointer!important;
  margin: 0!important;
  display: inline-flex!important;
  outline: 0!important;
  /* padding: 0!important; */
  position: relative!important;
  align-items: center!important;
  user-select: none!important;
  /* border-radius: 0!important; */
  vertical-align: middle!important;
  -moz-appearance: none!important;
  justify-content: center!important;
  text-decoration: none!important;
  background-color: transparent!important;
  -webkit-appearance: none!important;
  -webkit-tap-highlight-color: transparent!important;
  height: 30px;
  width: 30px;
}
.btn-dropdown-menu-custom:hover{
  background-color: rgba(0, 0, 0, 0.04)!important;
  box-shadow: 0 0px 5px rgb(0 0 0 / 30%) !important;
}
.btn-dropdown-menu-custom::before{
  border: 0!important;
  margin: 0!important;
  display: none!important;
}
.dropdown-menu{
  border-radius: 3px!important;
}
.listing-button-simple i{
  transition: all 0.2s ease-in-out;
}
.listing-button-simple:hover i{
  transform: scale(1.2);
  transition: all 0.2s ease-in-out;
}
.listing-button-simple:active{border: unset;}
button.btn-save:hover,nav a.btn-save:hover {
  background-color: var(--themeBtnColor) !important;
  border: 1px solid var(--themeBtnColor) !important;
  box-shadow: rgba(31, 35, 65, 0.37) 0px 20px 30px -11px!important;
}
.btn-save:disabled:hover {
  background-color: var(--light-blue) !important;
  border: 1px solid var(--light-blue) !important;
}
.button-container{display: flex !important;align-items: center;}
.button-container i{line-height: unset;}
.button-container span{padding: 0px 9px!important;}
input:read-only {
  user-select: none !important;
}
input:disabled{
  cursor: not-allowed;
}
input[type="text"]:read-only:focus,
input[type="url"]:read-only:focus,
input[type="email"]:read-only:focus {
  color: #555 !important;
  padding: 5px 14px;
  /* width: 100%; */
  /* border: unset; */
  /* border-radius: 3px; */
  /* transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out; */
  /* padding: 0.375rem 0.4rem; */
  margin: 0 0 5px 0 !important;
  /* box-shadow: 2px 2px 4px 0 rgb(0, 0, 0, 0.2); */
  /* width: 100%; */
  height: 40px !important;
  /* padding: 0 0 0 16px; */
  border-radius: 6px !important;
  outline: none !important;
  background-color: #f2f2f2 !important;
  border: 1px solid #e5e5e500 !important;
  box-shadow: unset !important;
  user-select: none;
  transition: all 0.3s cubic-bezier(0.15, 0.83, 0.66, 1) !important;
}

  /*=========== Input validation error START========*/

  .formField input.shake {
    border: 2px solid red!important;
    animation: shake 0.2s 3;
  }

  @keyframes shake {
    25% {
      translate: 6px 0;
    }
    50% {
      translate: -6px 0;
    }
    75% {
      translate: -6px 0;
    }
  }
  /* ========== Input validation error END ======= */
.Toastify .Toastify__toast-container {
  z-index: 9999999 !important;
}
.Toastify .Toastify__toast-container .Toastify__toast {
  box-shadow: 0 0px 10px rgb(0 0 0 / 20%)!important;
}
.Toastify__toast--success {
  background: linear-gradient(to right, #00b09b, #7ba335)!important;
  color: #fff!important;
}
.Toastify__toast--error{
  background: linear-gradient(to right, rgb(255, 95, 109), rgb(212 161 90))!important;
  color: #fff!important;
}
.Toastify__close-button{
  opacity: 1!important;
  color: #fff!important;
}
.Toastify__toast-icon svg{
 fill: #fff!important;
}
.Toastify__close-button svg{
  fill: #fff!important;
}
.Toastify__progress-bar--success {
  background: #21f126!important;
}
.Toastify__progress-bar--error {
  background: #d12715!important;
}
/* ============================================ */
.table-wrapper header{padding: 4px 0 12px;}
.rdt_TableHeadRow {
  font-weight: 700;
}
.rdt_TableRow div.lmTtlo:first-child{
      width: 100px;
}
.rdt_TableRow div.lmTtlo:not():first-child{
  justify-content: center!important;
}
.rdt_TableRow div:last-child  {
  /* min-width: max-content!important; */
  /* max-width: 100%!important;
  min-width: unset!important; */
  /* display: grid; */
}
.rdt_Pagination {
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
}
/* .count-container{
  width:100px;
  text-align: end!important;
  display: flex;
    justify-content: center;
} */
/* .table-actions-wrapper{width: 156px;} */

.table-header-custom{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap:10px;
  font-weight: 600;
}
.border-slip {
  border-top: 4px solid var(--light-blue);
}
.iJqAWV,.table-wrapper {
  min-height: 400px !important;
}
/* .chdMBN div:first-child {
  white-space: inherit !important;
  overflow: unset !important;
  text-align: initial !important;
  text-overflow: ellipsis !important;
} */



::-webkit-scrollbar {
  width: 12px; 
  background-color: #dddddd; 
}

::-webkit-scrollbar-thumb {
  background-color: #b9b9b9; 
  border-radius: 15px; 
}

::-webkit-scrollbar-track {
  border-radius: 15px; 
}




/* PDF Button */
/* .button-pdf {
  background-color: #007bff!important;
  color: #ffffff!important;
  border: 1px solid #007bff!important;
}

.button-pdf:hover {
  background-color: #0069d9!important;
  border-color: #0069d9!important;
} */

/* Record Payment Button */
.button-record-payment {
  background-color: #28a745!important;
  color: #ffffff!important;
  border: 1px solid #28a745!important;
}

.button-record-payment:hover {
  background-color: #218838!important;
  border-color: #218838!important;
}

/* Copy URL Button */
.button-copy-url {
  background-color: #6c757d!important;
  color: #ffffff!important;
  border: 1px solid #6c757d!important;
}

.button-copy-url:hover {
  background-color: #5a6268!important;
  border-color: #5a6268!important;
}
/* .button-email-invoice {
  background-color: #fd7e14!important;
  color: #ffffff!important;
  border: 1px solid #fd7e14!important;
}

.button-email-invoice:hover {
  background-color: #e66900!important;
  border-color: #e66900!important;
} */
.button-email-invoice {
  /* background-color: #6c757d8c !important;
  border: 1px solid #6c757d2e !important; */
  color: #ffffff !important;
  background-image: linear-gradient(45deg, #00acee, #5655d9)!important;
  border: 1px solid #00acee !important;
  font-weight: 600 !important;
}

.button-email-invoice:hover {
  background-image: linear-gradient(45deg, #00acee, #5655d9)!important;
  /* background-color: #6c757d!important; */
  border: 1px solid #00acee !important;

}

/* Email Invoice Button (Red) */
.button-pdf {
  background-color: #dc3545 !important;
  color: #ffffff !important;
  border: 1px solid #dc3545 !important;
}

.button-pdf:hover {
  background-color: #c82333 !important;
  border-color: #c82333 !important;
}

/* Email Invoice Button (Purple) */
/* .button-email-invoice {
  background-color: #6f42c1 !important;
  color: #ffffff !important;
  border: 1px solid #6f42c1 !important;
}

.button-email-invoice:hover {
  background-color: #5936a5 !important;
  border-color: #5936a5 !important;
} */

/* Email Invoice Button (Teal) */
/* .button-email-invoice {
  background-color: #20c997 !important;
  color: #ffffff !important;
  border: 1px solid #20c997 !important;
}

.button-email-invoice:hover {
  background-color: #17a589 !important;
  border-color: #17a589 !important;
} */



/* DATA TABELS vertical scrollbar */
.table-scroll-custom{
  -moz-scrollbar-width: thin;
  /* scrollbar-width: thin; */
  scrollbar-color: #cccccc;
  scroll-behavior: smooth;
  min-height: 400px!important;
}
.table-scroll-custom::-webkit-scrollbar {
  width: 10px;
  height: 10px; 
  background-color: #f5f5f5; 
}

.table-scroll-custom::-webkit-scrollbar-thumb {
  background-color: #ddd; 
  border-radius: 15px; 
}

.table-scroll-custom::-webkit-scrollbar-track {
  border-radius: 15px; 
}
.cursor-disable {
  cursor: not-allowed !important;
  pointer-events: unset !important;
}
.not-allowed {
  pointer-events: auto! important;
  cursor: not-allowed! important;
}
.cursor-pointer {
  cursor: pointer;
}
/* ==== Breadcrumbs  START =======*/
.breadcrumb-container {
  text-align: left;
}

.productsBreadcrumbs {
  /* width: 68%; */
  margin: 0 auto;
}
.breadcrumb-container .breadcrumbs {
  /* border: 1px solid #cbd2d9; */
  border-radius: 0.3rem;
  display: inline-flex;
  overflow: hidden;
  box-shadow: 0 0px 10px rgb(0 0 0 / 10%);
}

.breadcrumb-container .breadcrumbs__item {
  background: #fff;
  color: #333;
  outline: none;
  padding: 0.5em 0.5em 0.5em 1.25em;
  position: relative;
  text-decoration: none;
  transition: background 0.2s linear;
  font-weight: 700;
  font-size: 13px;
}
.breadcrumb-container .breadcrumbs__item:not(:last-child){
  cursor: pointer;
}

.breadcrumb-container .breadcrumbs__item:hover:after,
.breadcrumb-container .breadcrumbs__item:hover {
  background: #edf1f5;
}

.breadcrumb-container .breadcrumbs__item:focus:after,
.breadcrumb-container .breadcrumbs__item:focus,
.breadcrumb-container .breadcrumbs__item.is-active:focus {
  background: #323f4a;
  color: #fff;
}

.breadcrumb-container .breadcrumbs__item:after,
.breadcrumb-container .breadcrumbs__item:before {
  background: white;
  bottom: 0;
  clip-path: polygon(50% 50%, -50% -50%, 0 100%);
  content: "";
  left: 100%;
  position: absolute;
  top: 0;
  transition: background 0.2s linear;
  width: 1em;
  z-index: 1;
}

.breadcrumb-container .breadcrumbs__item:before {
  /* background: #cbd2d9; */
  background: var(--themeColor);
  margin-left: 1px;
}

.breadcrumb-container .breadcrumbs__item:last-child {
  border-right: none;
}

.breadcrumb-container .breadcrumbs__item.is-active {
  /* background: #edf1f5;
  color: var(--light-blue); */
  background: var(--themeColor);
  color: #fff;
}
/* .group-internal-salary-listing{
  width: 72%!important;
} */
/* ==== Breadcrumbs END ==========*/

/* ==== Telephone Input  Css START =======*/
.react-tel-input .form-control{
  width: 100%!important;
  height: inherit!important;
  padding-left: 48px!important;
  margin: 2px 0!important;
}
.react-tel-input{
  margin: 0 0 5px 0!important;
}
.react-tel-input .form-control:disabled{
  opacity: 0.5;
}
.input-container-tele .react-tel-input ul.country-list {
  color: #000!important;
  text-align: start!important;
}
.react-tel-input .country-list{
  text-align: start!important;
}

.loginView--container .input-container .input-container-tele .react-tel-input ul.country-list .highlight {
  position: relative;
  height: unset;
  width: unset;
  top: unset;
  left: unset;
  pointer-events: initial;
  opacity: 1;
}
/* ===== Telephone Input  Css END =======*/

/* ===== Global Notification CSS START =====  */
.notification-info{color:var(--notification-txt-info) ;background-color: var(--notification-bg-info);}
.notification-success{color:var(--notification-txt-success);background-color: var(--notification-bg-success);}
.notification-danger{color:var(--notification-txt-danger);background-color: var(--notification-bg-danger);}
.notification-primary{color:var(--notification-txt-primary);background-color: var(--notification-bg-primary);}
.notification-warning{color:var(--notification-txt-warning);background-color: var(--notification-bg-warning);}
.notification-info i{color:var(--notification-txt-info) ;}
.notification-success i{color:var(--notification-txt-success);}
.notification-danger i{color:var(--notification-txt-danger);}
.notification-primary i{color:var(--notification-txt-primary);}

/* ===== Global Notification CSS END =====  */



/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
.slideInUp {
  animation-name: slideInUp;
  animation-delay: 5s;
}

@keyframes slideInUp {
  from {
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.spacer {
  height: 32px;
}

/* =======Landing page START==========*/
.count-container .count-card .card-icon-container {
  height: 70px;
  width: 70px;
  text-align: left;
  margin: 0;
  padding: 12px;
}
.count-container .title{
  padding: 0 12px;
}
.donut-card  .doughnut-chart-wrapper{
  max-width: 100%;
}
.donut-card .doughnut-chart-wrapper canvas{
  margin: 0 auto;
  max-width: 100%!important;
  height: auto!important;
}
.donut-container .donut-card, .count-container .count-card{
  background-color: #fff;
    padding: 12px;
    border-radius: 10px;
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); */
    /* box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1); */
    /* box-shadow: 3px -1px 12px rgba(0, 0, 0, 0.3); */
    box-shadow: 0.2rem 0 0.5rem rgba(0, 0, 0, 0.1);
}
.count-container .count-card{
  padding: 10px;
}
.count-container .count-card .title{
  padding-bottom: 5px;
}
/* .count-container .count-card .title span{
  font-size: 2rem;
} */

.count-container .count-card .counts-container{
  padding: 10px;
  display: flex;
  justify-content: space-around;
}
.count-container .count-card .counts-container .single-type-count-container .count-title {
  font-weight: 600;
  font-size: 26px;
  /* line-height: 40px; */
  /* color: #272927; */
  /* margin-bottom: 4px; */
}

.count-container .count-card .counts-container .single-type-count-container span.count-tag {
  font-weight: 600;
  font-size: 13px;
  padding: 3px 5px;
  border-radius: 4px;
}

.count-card .card-icon-container .card-icon {
  padding: 7px;
  border-radius: 50%;
  height: 100%;
  width: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.count-card .card-icon-container .card-icon i{
  font-size: 20px;
}
.donut-container .title, .count-container .title{
  text-align: start;
  font-weight: 600; 
  /* height: 48px; */
  margin: 5px 0 0 0;
}
.donut-container  .donut-footer {
  margin: 5px 0;
  padding: 10px 0;
  height: 70px;
}
.donut-container  .donut-footer  .donut-footer-rec p{
  margin: 0;
  font-weight: 600;
  text-align: start;
}
section.usage{
  overflow: hidden;    
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0.2rem 0 0.5rem rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}
section.usage .usage-container{
  /* background-color: #fff;
  border-radius: 5px;
  padding: 22px 12px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); */
  background-color: #fff;
  border-radius: 10px;
  padding: 22px 12px;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); */
  border: 1px solid #ddddddb0;
  box-shadow: 3px -1px 12px rgba(0, 0, 0, 0.3);
  /* box-shadow: 0.2rem 0 0.5rem rgba(0, 0, 0, 0.1); */
}
.usage .usage-container :is(.accordion-button:not(.collapsed),
.accordion-button:focus) {
  outline: none;
  border-color: transparent;
  box-shadow: none;
  background-color: transparent;
}
.accordion-item:not(:last-child) .accordion-header-custom{
  border-bottom: 1px solid #ddd;
  color: #454545;
}
.accordion-header-custom button{
  font-weight: 600;
}
.accordion-header-custom .accordion-button:not(.collapsed){
  color: var(--themeColor);
}
.usage .usage-container .section-usage-header {
  /* font-size: 1.8rem;
  font-weight: 700;
  line-height: 43px; */
  margin-bottom: 15px;
  font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 38px;
    /* color: #000000; */
    text-align: start;
    padding-inline: 1rem;
}
.accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .accordion-button.collapsed{
  line-height: 29px;
  text-align: center;
  /* color: #000000; */
}
.usage .usage-container .usage-list {
  padding: 5px 0;
}
.usage .usage-container .usage-list .usage-step p,
.usage .usage-container .usage-list .usage-step .usage-step-title {
  text-align: left;
  padding-left: 14px;
}

.usage .usage-container .usage-list .usage-step img{
  width: 100%;
}

.usage .usage-container .usage-list .usage-step ol {
  text-align: left;
  font-size: 13px;
  /* text-align: -webkit-match-parent; */
  list-style-type: disc;
}
.price-faq-wrapper .custom-plan-wrapper {
  padding: 20px 0;
  background: -webkit-linear-gradient(334deg, #2379fa 0, #6cdedb 100%) !important;
  margin-bottom: 40px;
  font-weight: 600;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.price-faq-wrapper .custom-plan-wrapper .btn.btn-contact-us{
  border: 1px solid #fff;
  color: #fff;
}
.price-faq-wrapper .custom-plan-wrapper .btn.btn-contact-us:hover{
  background-color: #fff;
  color: var(--light-blue);
  outline: unset;
}
.paygroup-instructions-container .paygroup-instructions{text-align: left;}
.container.paygroup-instructions-container{
  background-color: #fff;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    min-height: fit-content!important;
}
/* =======Landing page END=========== */
.invoice-container .invoice-list, .email-wrapper .invoice-list{
  padding: 70px 20px;
  /* width: 70%; */
}
.count-input-text{text-align: end;}
.price-container{text-align: end;}
.invoice-actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  /* margin-bottom: 10px; */
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.invoice-actions .email-history{font-weight: 600;}
.showMoreDetailsContainer{
  transition: height 2s ease-in-out;
}
.invoice-actions .invoice-navBtn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 5px;
  padding: 5px 0;
  flex-wrap: wrap;
}
.invoice-container .invoice-list .invoice-actions .searchCreate-invoice {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 4px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 5px 0;
  flex-wrap: wrap;
}
.invoice-container
  .invoice-list
  .invoice-actions
  .searchCreate-invoice
  .invoice-searchContainer
  input {
  /* padding: 2px 12px; */
  background-color: #fff;
  border: 1px solid #aaa;
  border-radius: 3px;
  padding: 6px 16px;
  width: 100%;
  min-width: 180px;
  -webkit-transition: ease-in 100ms;
  -o-transition: ease-in 100ms;
  transition: ease-in 100ms;
}
.invoice-container
  .invoice-list
  .invoice-actions
  .searchCreate-invoice
  .invoice-searchContainer
  input:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
}
.invoice-container
  .invoice-list
  .invoice-actions
  .searchCreate-invoice
  .createInvoice
  a.btn {
  padding: 8px 15px !important;
  font-weight: 600;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  /* gap: 5px; */
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 13px;
  border-radius: 5px;
}
.invoice-container
  .invoice-list
  .invoice-actions
  .searchCreate-invoice
  .createInvoice
  a.btn
  span {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}
.invoice-container
  .invoice-list
  .invoice-actions
  .searchCreate-invoice
  .createInvoice button span{padding: 0;}

/* .invoice-container
  .invoice-list
  .invoice-actions
  .searchCreate-invoice
  .createInvoice
  a.btn
  i {
  font-size: 10px;
} */
.invoice-container .invoice-list table {
  text-align: start;
}
.table-balance {
  width: 5rem;
  text-transform: capitalize;
  text-align: end;
  padding: 0 5px;
}
.table-balance .table-balanceValue {
  overflow: unset !important;
  text-overflow: unset !important;
  word-break: break-word !important;
  white-space: break-spaces !important;
}
/* table tbody tr:hover {
  cursor: pointer;
} */

/* ===============Select template START ==================*/
.templatesContainer .submitContainer {
  /* text-align: end; */
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.templatesContainer .temp-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.templatesContainer .temp-header a {
  padding: 0 0.25rem;
  font-weight: bold;
  color: var(--themeBtnColor);
}
.templatesContainer .temp-header a:hover {
  color: var(--buttonHover);
}
.templatesContainer .termsConditions a {
  color: #000;
}
.templatesContainer .termsConditions a:hover {
  color: #00acee;
}
.templatesContainer .submitContainer button {
  font-weight: 600;
  margin: 10px 0;
  text-transform: capitalize;
  padding: 5px 15px;
  border-radius: 0.25rem;
}

/* ================ */

/* =====action button Start===== */
.searchCreate-invoice .createInvoice button.action-button-group {
  display: inline-flex;
    align-items: center;
    gap: 10px;
}
.searchCreate-invoice .createInvoice button.action-button-group .action-button-inner {
  width: max-content;
}
/* ==========Send ===============*/

.invoice-container .invoice-list table tbody tr td:nth-child(2),
.invoice-container .invoice-list table thead th,
.invoice-container .invoice-list table tfoot {
  font-weight: 600;
}
.invoice-container .invoice-list table tbody {
  font-size: 0.8rem;
}
.invoice-container .invoice-list table thead tr th:last-child,
.invoice-container .invoice-list table tbody tr td:last-child,
.invoice-container .invoice-list table tfoot {
  text-align: end;
}
/* .invoice-container .invoice-list table tbody tr:last-child td{
  border-bottom: 2px solid #000000;
} */
.invoice-container .invoice-list table tbody tr {
  border-bottom-width: 1px;
}
/* .invoice-container .invoice-list table tbody tr:hover {
  background-color:#00000013;
} */
.invoice-container .invoice-list table tbody tr a {
  display: contents;
  color: black;
}
.invoice-container .invoice-list table tbody tr a td {
  padding: 0.5rem;
}
.invoice-container .invoice-list table tfoot tr td {
  border-bottom-width: 0;
}
.invoice-container .invoice-list .invoice-list-footer {
  padding: 0.8rem;
  color: #bbb;
  text-align: start;
}

/* ========================== */
.invoice-detail {
  margin: auto;
}
.invoice-detail .invoice-actions .invoice-navBtn .btn,
.invoice-actions .invoice-navBtn .btn,
.invoice-actions-export .btn {
  font-weight: 600 !important;
  /* background: #e5e7eb; */
  background: #fff;
  font-size: 14px;
  color: #374151;
  /* padding: 8px 16px ; */
  border: 1px solid #d1d5db;
  border-radius: 4px;
  -webkit-font-smoothing: antialiased;
  font-weight: 600;
  display: inline-block;
  overflow: hidden;
  text-decoration: none;
  /* min-width: 60px ; */
  cursor: pointer;
  -webkit-transition: ease-in 100ms;
  -o-transition: ease-in 100ms;
  transition: ease-in 100ms;
  box-shadow: 0 1px 5px rgb(0 0 0 / 15%);
}
.invoice-actions .btn span {padding-left: 10px;width: max-content;}
.invoice-detail .invoice-actions .invoice-actions-export .invoice-actions-update{
  display: flex;
    flex-wrap: wrap;
    gap: 5px;
}
.invoice-detail .invoice-actions .invoice-actions-export {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 5px;
  flex-wrap: wrap;
}

.new-edit-invoice-container .invoiceFormContainer {
  background-color: #ffff;
  padding: 15px 20px;
  -webkit-box-shadow: 0 0px 10px rgb(0 0 0 / 20%);
  box-shadow: 0 0px 10px rgb(0 0 0 / 20%);
  -webkit-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}

.invoiceFormContainer .formStepsContainer {
  /* display: flex;
  justify-content:space-between ; */
  min-height: 300px;
  -webkit-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.invoiceFormContainer .formStepsContainer * {
  -webkit-transition: all 0.5s ease-in-out;

  -o-transition: all 0.5s ease-in-out;

  transition: all 0.5s ease-in-out;
}
.invoiceFormContainer .formNavigateContainer {
  text-align: end;
  padding-top: 10px;
}
.invoiceFormContainer .formNavigateContainer button {
  margin: 0 6px;
  padding: 7px 15px!important;
}
/* .invoiceFormContainer .formNavigateContainer button.btn-save:hover {transform: translate(0px, -5px);} */
.new-edit-invoice-container .invoiceFormContainer .formStepsContainer .salary-description-table {
  display: flex;width:100%;
}
.invoiceFormContainer .formNavigateContainer i {
  font-size: 12px;
}
.InvoiceInfo .prefixInfo {
  position: absolute;
  top: 50%;
  right: -22px;
  transform: translate(0px, -50%);
  font-size: 17px;
  cursor: pointer;
}
.sideSetting {
  text-align: start;
  font-size: 0.8rem;
  padding-top: 11px;
  position: sticky;
  top: 15%;
  background-color: #fff;
  padding: 15px 20px;
  border-radius: 4px;
  margin-top: 16px;
}
.opacity-four{opacity: 0.4!important;}
.sideSetting .taxDisBodyFileds {
  /* display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; */
  font-weight: 600;
  padding: 4px;
}
.sideSetting .taxDisBodyFileds label{margin-bottom: 3px;padding-left: 5px;}
.percent-postfix{
  position: relative;
}
.percent-postfix::after{
  content:'%';
  position: absolute;
  right: 0;
    top: 0;
    transform: translate(-65%, 60%);
}

/* ===========PersonalDetails Form Step 1 START*/

/* .PersonalDetails {
  
} */
.PersonalDetails .formStepOne {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  text-align: start;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.PersonalDetails .formStepOne {
  color: #555;
}
.PersonalDetails .formStepOne .invoiceHeader , .createGroup-container .invoiceHeader{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  /* align-items: center; */
}
.PersonalDetails .formStepOne .invoiceHeader .formField input,
 .createGroup-container .invoiceHeader .formField input{
  font-weight: 700;
  padding: 5px 10px;
  font-size: 19px;
  color: #555;
  text-transform: uppercase;
  /* padding: 5px; */
  /* width: 100%; */
  /* border: unset; */
  /* border-radius: 3px; */
  /* transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out; */
  padding: 0.375rem 0.4rem;
  margin: 0 0 5px 0;
  /* box-shadow: 2px 2px 4px 0 rgb(0, 0, 0, 0.2); */
  /* width: 100%; */
  height: 40px;
  /* padding: 0 0 0 16px; */
  border-radius: 6px;
  outline: none;
  background-color: #f2f2f2;
  border: 1px solid #e5e5e500;
  -webkit-transition: all 0.3s cubic-bezier(0.15, 0.83, 0.66, 1);
  -o-transition: all 0.3s cubic-bezier(0.15, 0.83, 0.66, 1);
  transition: all 0.3s cubic-bezier(0.15, 0.83, 0.66, 1);
}
.PersonalDetails .formStepOne .invoiceHeader .invoiceLogo,
.invoiceFooter .invoiceFooterImage .invoiceLogo,
.bussinessLogo .invoiceLogo ,
.createGroup-container .invoiceHeader .invoiceLogo,
.image-wrapper
{
  /* border:  1px solid #ced4da; */
  padding: 0 5px;
  /* border-radius: 3px; */
  text-align: center;
  /* height: 70px; */
  /* cursor: pointer; */
}
.bussinessLogo .invoiceLogo .bgImage-warning{text-align: left;color: var(--text-danger)}
/* .PersonalDetails .formStepOne .invoiceHeader .invoiceLogo input,
  .invoiceFooter .invoiceFooterImage .invoiceLogo input {
    height:73%;
  } */
/* ==============Logo Container CSS START  =====================*/
.PersonalDetails .formStepOne .invoiceHeader .invoiceLogo .logoContainer,
.invoiceFooterImage .invoiceLogo,
.bussinessLogo .invoiceLogo .logoContainer,
.createGroup-container .invoiceHeader .invoiceLogo .logoContainer,
.image-wrapper .image-wrapper-inner {
  width: 200px;
  height: 134px;
  border: 1px solid #ddd;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-bottom: unset;
  padding-top: unset;
  border-radius: 4px;
  /* overflow: hidden; */
  /* border-radius: 5px; */
  cursor: pointer;
  background-color: #f2f2f2;
}
.image-wrapper .image-wrapper-inner{width:100%;height: 200px;}
.bussinessLogo .invoiceLogo .logoContainer{
  height: 175px;
}
.bussinessLogo .invoiceLogo .logoContainer {
  width: 100%;
}
.invoiceFooterImage .invoiceLogo {
  width: 171px;
  height: 90px;
}
.photoDetail img {
  /* max-height: 200px; */
  height: auto;
}
.photoDetail form .btn {
  font-weight: 600;
  border: 1px solid;
  margin: 0 5px;
}
.photoDetail form textarea {
  resize: none;
}
.PersonalDetails .formStepOne .invoiceHeader .invoiceLogo .logoContainer input,
.invoiceFooterImage .invoiceLogo input,
.bussinessLogo .invoiceLogo .logoContainer input,
.createGroup-container .invoiceHeader .invoiceLogo .logoContainer input,
.image-wrapper .image-wrapper-inner input{
  position: absolute;
  width: 100%;
  height: 100%;
  /* visibility: hidden; */
  left: 0;
  z-index: 5;
  cursor: pointer;
  background-color: transparent;
  border: unset;
}
.PersonalDetails
  .formStepOne
  .invoiceHeader
  .invoiceLogo
  .logoContainer
  input:focus,
.bussinessLogo .invoiceLogo .logoContainer input:focus,
.createGroup-container .invoiceHeader .invoiceLogo .logoContainer input:focus,
.image-wrapper .image-wrapper-inner input:focus {
  background-color: transparent !important;
  box-shadow: 0 0 0 0.25rem rgba(13,110,253,.25);
}
.PersonalDetails .formStepOne .invoiceHeader .invoiceLogo .logoContainer > *,
.bussinessLogo .invoiceLogo .logoContainer > *,
.createGroup-container .invoiceHeader .invoiceLogo .logoContainer,
.image-wrapper .image-wrapper-inner 
{
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.PersonalDetails
  .formStepOne
  .invoiceHeader
  .invoiceLogo
  .logoContainer
  .overlay,
.bussinessLogo .invoiceLogo .logoContainer .overlay,
.createGroup-container .invoiceHeader .invoiceLogo .logoContainer .overlay,
.image-wrapper .image-wrapper-inner .overlay {
  display: none;
  position: absolute;
  z-index: 4;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.PersonalDetails
  .formStepOne
  .invoiceHeader
  .invoiceLogo
  .logoContainer:hover
  .overlay,
.bussinessLogo .invoiceLogo .logoContainer:hover .overlay,
.createGroup-container .invoiceHeader .invoiceLogo .invoiceLogo .logoContainer:hover .overlay,
.image-wrapper .image-wrapper-inner:hover .overlay
{
  display: block;
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.PersonalDetails .formStepOne .invoiceHeader .invoiceLogo .logoContainer .logolabel,
.bussinessLogo .invoiceLogo .logoContainer .logolabel,
.createGroup-container .invoiceHeader .invoiceLogo .logoContainer .logolabel,
.image-wrapper .image-wrapper-inner .imagelabel{
  position: absolute;
  /* width: 100%; */
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  /* height: 100%; */
  top: 50%;
  /* visibility: hidden; */
  left: 50%;
  z-index: 2;
  cursor: pointer;
  background-color: transparent;
  font-weight: 600;
  font-size: 13px;
}

.PersonalDetails .formStepOne .invoiceHeader .invoiceLogo .logoContainer  img.logoImage,
.bussinessLogo .invoiceLogo .logoContainer img.logoImage,
.createGroup-container .invoiceHeader .invoiceLogo .logoContainer  img.logoImage,
.image-wrapper .image-wrapper-inner img.customImage
{
  position: absolute;
  top: 50%;
  z-index: 3;
  width: auto;
  max-height: 100%;
  height: auto;
  max-width: 100%;
  -webkit-transform: translate(0%, -50%);
  -ms-transform: translate(0%, -50%);
  transform: translate(0%, -50%);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
.PersonalDetails .formStepOne .invoiceHeader .invoiceLogo .logoContainer input[type="file"],
.invoiceFooterImage .invoiceLogo input[type="file"],
.bussinessLogo .invoiceLogo input[type="file"],
.createGroup-container .invoiceHeader .invoiceLogo .logoContainer input[type="file"],
.image-wrapper .image-wrapper-inner input[type="file"] 
{
  /* color: #f3f4f6!important; */
  color: transparent !important;
  color: transparent !important;
}
.sideSetting input[type="checkbox"] {
  height: unset !important;
  vertical-align: middle !important;
  margin: 0 !important;
}
.PersonalDetails .formStepOne .invoiceHeader .invoiceLogo .logoContainer input::-webkit-file-upload-button,
.invoiceFooterImage .invoiceLogo input::-webkit-file-upload-button,
.bussinessLogo .invoiceLogo input::-webkit-file-upload-button,
.createGroup-container .invoiceHeader .invoiceLogo .logoContainer input::-webkit-file-upload-button,
.image-wrapper .image-wrapper-inner input::-webkit-file-upload-button
{
  font-weight: bold;
  color: dodgerblue;
  padding: 0.5em;
  border: thin solid grey;
  border-radius: 3px;
  visibility: hidden;
  z-index: 4;
}
.PersonalDetails .formStepOne .invoiceHeader .invoiceLogo  .logoContainer input::file-selector-button,
.invoiceFooterImage .invoiceLogo input::file-selector-button,
.bussinessLogo .invoiceLogo input::file-selector-button,
.createGroup-container .invoiceHeader .invoiceLogo .logoContainer input::file-selector-button,
.image-wrapper .image-wrapper-inner input::file-selector-button
{
  font-weight: bold;
  color: dodgerblue;
  padding: 0.5em;
  border: thin solid grey;
  border-radius: 3px;
  visibility: hidden;
  opacity: 0;
  display: none;
  z-index: 4;
  height: 100%;
  color: transparent;
  background-color: transparent;
}
/* {
  height: 120px;
    width: 200px;
} */
.PersonalDetails .formStepOne .invoiceHeader .invoiceLogo .logoContainer span.showlabel,
.bussinessLogo .invoiceLogo .logoContainer span.showlabel,
.createGroup-container .invoiceHeader .invoiceLogo .logoContainer span.showlabel,
.image-wrapper .image-wrapper-inner span.showlabel
{
  font-weight: 600;
  display: none;
  -webkit-transition: all 0.6s ease-in-out;
  -o-transition: all 0.6s ease-in-out;
  transition: all 0.6s ease-in-out;
  z-index: 4;
  position: absolute;
  bottom: 0;
  width: 100%;
  text-transform: capitalize;
  background-color: rgba(255, 255, 255, 0.7);
}
.PersonalDetails .formStepOne .invoiceHeader .invoiceLogo .logoContainer  span.removeImage,
.bussinessLogo .invoiceLogo .logoContainer span.removeImage,
.createGroup-container .invoiceHeader .invoiceLogo .logoContainer  span.removeImage,
.image-wrapper .image-wrapper-inner span.removeImage
{
  z-index: 10;
  position: absolute;
  top: 7px;
  right: 10px;
  color: #ffff;
  text-shadow: 0 0 10px black;
  display: none;
  padding: 0 5px;
}
.PersonalDetails
  .formStepOne
  .invoiceHeader
  .invoiceLogo
  .logoContainer:hover
  span.removeImage,
.bussinessLogo .invoiceLogo .logoContainer:hover span.removeImage,
.createGroup-container .invoiceHeader .invoiceLogo .logoContainer:hover span.removeImage,
.image-wrapper .image-wrapper-inner:hover span.removeImage
{
  display: block;
}
.PersonalDetails
  .formStepOne
  .invoiceHeader
  .invoiceLogo
  .logoContainer:hover
  span.showlabel,
.bussinessLogo .invoiceLogo .logoContainer:hover span.showlabel,
.createGroup-container .invoiceHeader .invoiceLogo .logoContainer:hover span.showlabel,
.image-wrapper .image-wrapper-inner:hover span.showlabel 
{
  display: block;
  -webkit-transition: all 0.6s ease-in-out;
  -o-transition: all 0.6s ease-in-out;
  transition: all 0.6s ease-in-out;
  font-size: 12px;
  padding: 5px 0;
}
.PersonalDetails
  .formStepOne
  .invoiceHeader
  .invoiceLogo
  .logoContainer
  span.showlabel
  i,
.bussinessLogo .invoiceLogo .logoContainer span.showlabel i ,
.createGroup-container .invoiceHeader .invoiceLogo .logoContainer span.showlabel i,
.image-wrapper .image-wrapper-inner span.showlabel i 
{
  font-weight: 600;
  font-size: 11px;
  /* vertical-align: middle; */
}
/*==========Logo Container CSS END========================  */

.invoiceFooter .invoiceFooterImage .galleryContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.PersonalDetails .formStepOne .invoiceHeader .invoiceLogo .logoNote,
.invoiceFooter .invoiceFooterImage .invoiceLogo .logoNote {
  font-weight: 600;
}
.invoiceFooter .invoiceFooterImage .invoiceLogo .logoNote {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}
/* .invoiceFooter .invoiceFooterImage .addedPictures{
    height: 120px;
    width: 200px;
  } */
.galleryContainer .singleImage {
  width: 223px;
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
  position: relative;
  /* cursor: pointer; */
  text-align: left;
  box-shadow: 0 0px 2px rgb(0 0 0 / 20%);
}
.galleryContainer .singleImage .details {
  margin: 5px 0;
  word-break: break-word;
}
.galleryContainer .singleImage .details p {
  margin: 0;
  /* word-break: break-word; */
}

.galleryContainer .singleImage .imageContainer {
  z-index: 3;
  width: 100%;
  position: relative;
  height: 150px;
  /* max-height: 200px; */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.galleryContainer .singleImage .imageContainer img {
  width: 100%;
  height: auto;
  max-height: 100%;
}
.galleryContainer .singleImage .imageActions {
  display: none;
  position: absolute;
  right: 14px;
  bottom: 10px;
  font-size: 20px;
  color: white;
  font-weight: bolder;
  text-shadow: 0 0 10px black;
  /* cursor: pointer; */
  z-index: 4;
}
.galleryContainer .singleImage .imageActions .edit,
.galleryContainer .singleImage .imageActions .remove {
  cursor: pointer;
  margin: 0.35rem;
  padding: 0.25rem;
}
/* .galleryContainer .singleImage span.edit {
    display: none;
    position: absolute;
    right: 19px;
    bottom: 10px;
    font-size: 20px;
    color: white;
    font-weight: bolder;
    text-shadow: 0 0 10px black;
    cursor: pointer;
    z-index: 4;
  } */
.galleryContainer .singleImage:hover .imageActions {
  display: block;
}
/* .invoiceFooterImage .invoiceLogo input::file-selector-button {
    font-weight: bold;
    color: dodgerblue;
    padding: 0.5em;
    border: thin solid grey;
    border-radius: 3px;
    visibility: hidden;
    z-index: 4;
} */
.PersonalDetails .formStepOne .fromPersonalDetails,
.PersonalDetails .formStepOne .InvoiceInfo,
.PersonalDetails .formStepOne .toPersonalDetails {
  /* padding: 16px;
  margin: 5px; */
  padding: 16px;
  background-color: #F6FAFC;
  margin: 5px;
  border: 1px solid #ced4da;
  border-radius: 2px;
}
.formStepOne .InvoiceInfo{
  background: #fff!important;
  border: unset !important; 
}
.PersonalDetails .formStepOne .toPersonalDetails .formField .nameSuggest,
.invoiceDescriptionRow .formField .nameSuggest {
  position: relative;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.PersonalDetails
  .formStepOne
  .toPersonalDetails
  .formField
  .nameSuggest
  .autosuggestDropDownContainer
  .autosuggestDropDown,
.invoiceDescriptionRow
  .formField
  .nameSuggest
  .autosuggestDropDownContainer
  .autosuggestDropDown {
  position: absolute;
  top: calc(100% - 6px);
  width: 100%;
  min-height: 50px;
  max-height: 230px;
  overflow-y: auto;
  /* left: calc(100%); */
  background-color: #e9ecef !important;
  /* display: none; */
  z-index: 4;
}
.PersonalDetails
  .formStepOne
  .toPersonalDetails
  .formField
  .nameSuggest
  .autosuggestDropDownContainer
  .autosuggestDropDown
  ul,
.invoiceDescriptionRow
  .formField
  .nameSuggest
  .autosuggestDropDownContainer
  .autosuggestDropDown
  ul {
  list-style-type: none;
  padding: 0;
  margin-bottom: 0;
}
.PersonalDetails
  .formStepOne
  .toPersonalDetails
  .formField
  .nameSuggest
  .autosuggestDropDownContainer
  .autosuggestDropDown
  ul
  li,
.invoiceDescriptionRow
  .formField
  .nameSuggest
  .autosuggestDropDownContainer
  .autosuggestDropDown
  ul
  li {
  cursor: pointer;
  margin: 5px 0;
  /* font: italic bold 16px/1.5 emoji; */
  /* font-weight: 500; */
  text-align: start;
  font-size: 16px;
  line-height: 1.5;
  padding: 4px 9px;
  border-bottom: 1px solid #ddd;
}
.PersonalDetails
  .formStepOne
  .toPersonalDetails
  .formField
  .nameSuggest
  .autosuggestDropDownContainer
  .autosuggestDropDown
  ul
  li:hover,
.invoiceDescriptionRow
  .formField
  .nameSuggest
  .autosuggestDropDownContainer
  .autosuggestDropDown
  ul
  li:hover {
  background-color: #fff;
}
/* width */
.PersonalDetails
  .formStepOne
  .toPersonalDetails
  .formField
  .nameSuggest
  .autosuggestDropDownContainer
  .autosuggestDropDown::-webkit-scrollbar,
.invoiceDescriptionRow
  .formField
  .nameSuggest
  .autosuggestDropDownContainer
  .autosuggestDropDown::-webkit-scrollbar {
  width: 6px;
  display: none;
}
.PersonalDetails
  .formStepOne
  .toPersonalDetails
  .formField
  .nameSuggest
  .autosuggestDropDownContainer:hover
  .autosuggestDropDown::-webkit-scrollbar,
.invoiceDescriptionRow
  .formField
  .nameSuggest
  .autosuggestDropDownContainer:hover
  .autosuggestDropDown::-webkit-scrollbar {
  display: block;
}
/* Track */
.PersonalDetails
  .formStepOne
  .toPersonalDetails
  .formField
  .nameSuggest
  .autosuggestDropDownContainer
  .autosuggestDropDown::-webkit-scrollbar-track,
.invoiceDescriptionRow
  .formField
  .nameSuggest
  .autosuggestDropDownContainer
  .autosuggestDropDown::-webkit-scrollbar-track {
  background: #f1f1f1;
  display: none;
}

/* Handle */
.PersonalDetails
  .formStepOne
  .toPersonalDetails
  .formField
  .nameSuggest
  .autosuggestDropDownContainer
  .autosuggestDropDown::-webkit-scrollbar-thumb,
.invoiceDescriptionRow
  .formField
  .nameSuggest
  .autosuggestDropDownContainer
  .autosuggestDropDown::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.PersonalDetails
  .formStepOne
  .toPersonalDetails
  .formField
  .nameSuggest
  .autosuggestDropDownContainer
  .autosuggestDropDown::-webkit-scrollbar-thumb:hover,
.invoiceDescriptionRow
  .formField
  .nameSuggest
  .autosuggestDropDownContainer
  .autosuggestDropDown::-webkit-scrollbar-thumb:hover {
  background: #555;
  display: none;
}
/*.PersonalDetails
   .formStepOne
  .toPersonalDetails
  .formField
  .nameSuggest:hover
  .autosuggestDropDownContainer
  .autosuggestDropDown,
.invoiceDescriptionRow
  .formField
  .nameSuggest:hover
  .autosuggestDropDownContainer
  .autosuggestDropDown {
  display: block;
} */
.nameSuggest .autosuggestDropDownContainer.active {
  display: block;

}

/* .nameSuggest:is(:focus,:focus-within,:focus-visible) */
.PersonalDetails .formStepOne :is(.fromPersonalDetails,.InvoiceInfo,.toPersonalDetails) .formField,
/* .PersonalDetails .formStepOne .InvoiceInfo .formField,
.PersonalDetails .formStepOne .toPersonalDetails .formField, */
.invoiceDescription .descriptionBody .invoiceDescriptionRow .formField,
.createGroup-container .fromPersonalDetails .formField
{
  width: 100%;
  /* margin: 15px 0; */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.PersonalDetails .formStepOne label,
:is(.paymentrecord, .createGroup-container) .formField label {
  font-weight: 600;    padding: 0 5px;
}
/* .PersonalDetails .formStepOne .fromPersonalDetails .formField input,
.PersonalDetails .formStepOne .InvoiceInfo .formField input,
.PersonalDetails .formStepOne .InvoiceInfo .formField select,
.PersonalDetails .formStepOne .toPersonalDetails .formField input, */
/* .invoiceDescription .descriptionBody .invoiceDescriptionRow .formField select, */
.PersonalDetails .formStepOne :is(.fromPersonalDetails,.InvoiceInfo ,.toPersonalDetails) :is(input,select),
.invoiceDescription .descriptionBody .invoiceDescriptionRow .formField :is(input,select),
.sideSetting .taxDisBodyFileds input,
.createGroup-container .formField input
{
  color: #555;
  padding: 10px 15px;
  margin: 0 0 15px 0;
  /* height: 46px; */
  border-radius: 6px;
  outline: none;
  background-color: #f2f2f2;
  border: 1px solid #e5e5e500;
  -webkit-transition: all 0.3s cubic-bezier(0.15, 0.83, 0.66, 1);
  -o-transition: all 0.3s cubic-bezier(0.15, 0.83, 0.66, 1);
  transition: all 0.3s cubic-bezier(0.15, 0.83, 0.66, 1);
}
/* .PersonalDetails .formStepOne :is(.fromPersonalDetails,.InvoiceInfo ,.toPersonalDetails) :is(input,select):focus,
.invoiceDescription .descriptionBody .invoiceDescriptionRow .formField :is(input,select):focus,
.sideSetting .taxDisBodyFileds input:focus,
.createGroup-container .formField input:focus{
  border-color: var(--themeColor)!important;
  box-shadow: none;
} */
:is(input,select,textarea):focus {
  box-shadow: 0 0 0 .1rem #24b6f770!important;
}
.sideSetting .taxDisBodyFileds input[type="color"] {
  height: 23px;
  padding: 5px 10px;
}
/* .PersonalDetails .formStepOne .invoiceHeader .formField,
  .invoiceDescription .descriptionBody .invoiceDescriptionRow .formField,
  .PersonalDetails .formStepOne .invoiceHeader .invoiceLogo{
    margin: 5px 0;
   } */

   /* .PersonalDetails .formStepOne .invoiceHeader .formField input:focus,
   .PersonalDetails .formStepOne .invoiceHeader .invoiceLogo input:focus,
   .PersonalDetails .formStepOne .fromPersonalDetails .formField input:focus,
   .PersonalDetails .formStepOne .toPersonalDetails .formField input:focus,
   .PersonalDetails .formStepOne .InvoiceInfo .formField input:focus, */
   /* .invoiceDescription .descriptionBody .invoiceDescriptionRow .formField textarea:focus  */
.PersonalDetails .formStepOne :is(.invoiceHeader,.fromPersonalDetails,.toPersonalDetails,.InvoiceInfo) :is(.formField,.invoiceLogo) input:focus
.createGroup-container .formField input:focus,
.invoiceDescription .descriptionBody .invoiceDescriptionRow .formField :is(input,textarea):focus
{
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
  border-radius: 3px;
}
.PersonalDetails .formStepOne .fromPersonalDetails .formField input.businessNumberField {
  vertical-align: top;
}
.PersonalDetails .formStepOne .showMoreDetails {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  margin: 15px 0;
  padding: 0 5px;
  /* transition: all 0.5s ease-in-out; */
}
.PersonalDetails .formStepOne .showMoreDetails .btnDetail {
  font-weight: 600;
}
.PersonalDetails .formStepOne .showMoreDetails.borderUp {
  border-top: 1px solid #cdc5c5;
  -webkit-animation: opac 1s;
  animation: opac 1s;
}
.PersonalDetails .formStepOne .showMoreDetails.borderDown {
  border-bottom: 1px solid #cdc5c5;
  -webkit-animation: opac 1s;
  animation: opac 1s;
}

.PersonalDetails .formStepOne .fromPersonalDetails * {
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
/* .formField {
    
  } */

/* PersonalDetails Form Step 1 END */
/* InvoiceDescription Form Step 2 START  */
.invoiceDescription {
  padding: 0 10px;
  font-size: 0.8rem;
}
.invoiceDescription .descriptionHead {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  /* align-items: center; */
  border-top: 1px solid;
  border-bottom: 1px solid;
  /* padding: 5px 0; */
  -ms-flex-pack: distribute;
  justify-content: space-around;
  font-weight: 600;
  text-align: center;
}
/* .invoiceDescription .descriptionHead div:first-child {
  text-align: start;
} */
.invoiceDescription .descriptionHead div:last-child {
  text-align: center;
}
.invoiceDescription .descriptionHead > * {
  padding: 5px 10px;
}
.invoiceDescription .addDescriptionRow {
  margin: 5px 0;
  border-bottom: 1px solid #cdc5c5;
  padding: 15px 0;
  text-align: start;
}
/* .invoiceDescription .addDescriptionRow:not(:last-child){border-bottom: 1px solid #cdc5c5;} */
.invoiceDescription .invoiceDescriptionRow {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 10px 0;
  border-bottom: 1px dashed #9f9f9f;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  /* margin-bottom: 5px; */
}
.invoiceDescription .invoiceDescriptionRow input[type="checkbox"] {
  padding: 0.375rem 0.75rem;
  margin: 8px;
}
.invoiceDescription .invoiceDescriptionRow .amount-container {
  padding: 0.375rem 0;
  margin: 5px;
}
/* .invoiceDescription .descriptionBody .invoiceDescriptionRow .formField input */
.invoiceDescription .descriptionBody .invoiceDescriptionRow .formField input {
  /* width: 100%;
  border: 1px solid #ced4da;
  border-radius: 3px;
  -webkit-transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  -o-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  padding: 0.375rem 0.75rem; */
  margin: 5px;
  font-size: 0.9rem;
  border: 1px solid #ced4da;
}
.invoiceDescription .descriptionBody div.invoiceDescriptionRow:nth-child(even) {
  background-color: #dddddd3b;
}
/* .invoiceDescription .descriptionBody .invoiceDescriptionRow .formField {
  margin: 5px;
} */
.invoiceDescription
  .descriptionBody
  .invoiceDescriptionRow
  .item-row
  .formField
  textarea {
  resize: none;
  width: 100%;
  margin: 5px 0;
  padding: 5px;
  color: #555;
  font-size: 0.8rem;
}
.invoiceDescription .descriptionBody .invoiceDescriptionRow div:nth-child(4) {
  text-align: center;
}
.invoiceDescription .descriptionBody .invoiceDescriptionRow .item-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.invoiceDescription
  .descriptionBody
  .invoiceDescriptionRow
  .item-row
  .item-row-action {
  margin: 5px 0;
}
.invoiceDescription
  .descriptionBody
  .invoiceDescriptionRow
  .item-row
  .item-row-action
  button
  i {
  color: #ffff;
  /* vertical-align: middle; */
}
.invoiceFormContainer .formStepsContainer .invoiceDescription .invoiceTotal {
  padding: 10px 0;
}
/* InvoiceDescription Form Step 2 END  */
/*  */
.taxationDiscountContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.taxationContainer {
  padding: 10px;
}
/* ===============recordPayment START================*/
.paymentrecord .formField,
.emailDataContainer .formField {
  /* width: 100%; */
  /* margin: 15px 0; */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items:center;
  padding: 5px 0;
  flex-wrap: wrap;
}
.paymentrecord .formField {
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  flex-wrap: wrap;
}
.paymentrecord .formField label,
.emailDataContainer .formField label {
  font-weight: 600;
}
.paymentrecord .formField input,
.emailDataContainer .formField input {
  /* width: 100%; */
  padding: 5px;
  border: 1px solid #ced4da;
  border-radius: 3px;
  -webkit-transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  -o-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  padding: 0.375rem 0.75rem;
}

.paymentrecord .formField :is(textarea,select) {
  resize: none;
  border-radius: 3px;
}
.paymentrecord .payment-form-submit{display: flex;gap: 5px;margin-bottom: 5px;justify-content: flex-end;}
.paymentrecord .btn {
  font-weight: 600 !important;
  background: #e5e7eb;
  font-size: 14px;
  color: #374151;
  padding: 6px 10px;
  border: 1px solid #697588;
  border-radius: 4px;
  -webkit-font-smoothing: antialiased;
  font-weight: 600;
  display: inline-block;
  overflow: hidden;
  text-decoration: none;
  min-width: 60px;
  cursor: pointer;
  -webkit-transition: ease-in 100ms;
  -o-transition: ease-in 100ms;
  transition: ease-in 100ms;
  /* height: 32px; */
}
/* .paymentrecord .btn:disabled:hover{
  background-color: var(--light-blue)!important;
  border-color: var(--light-blue);
} */
/* .paymentrecord .paymentContainer {
  display: flex;
  align-items: flex-start;
} */
.paymentrecord .paymentContainer .paymentContainerInner {
  font-weight: 700;
  display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  justify-content: space-between;
}
.paymentrecord
  .paymentContainer
  .paymentContainerInner
  .dueBalanceContainer
  .dueBalanceLabel {
  color: #dc3545;
}
.paymentrecord .paymentContainer .singlePaymentRecordContainer {
  border-top: 1px solid #ddd;
}
 .paymentrecord .singlePaymentRecord .singlePaymentRecord-detail .method-note-container i.fa{padding: 0 5px;font-size: 16px;
  vertical-align: middle;}
  .paymentrecord .singlePaymentRecord .singlePaymentRecord-detail .method-note-container .payment-method {font-weight: 600;text-transform: capitalize;}
.paymentrecord .singlePaymentRecord {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 15px 0;
  border-bottom: 1px solid #ddd;
  gap: 10px;
}
.paymentrecord .singlePaymentRecord:hover{background-color: #eeeeee;}
.singlePaymentRecord-detail{    display: flex;
  justify-content: space-between;
  gap: 21px;}
.paymentrecord .singlePaymentRecord .singlePaymentRecordActions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 10px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  /* transition: all;
  transition: all 0.7s ease-in-out; */
}
.paymentrecord .singlePaymentRecord .singlePaymentRecordActions button {
  background: none;
  border: 2px solid #555;
  padding: 4px 8px!important;
  margin: 2px 0;
  -webkit-transition: -webkit-transform 0.7s ease-out;
  transition: -webkit-transform 0.7s ease-out;
  -o-transition: transform 0.7s ease-out;
  transition: transform 0.7s ease-out;
  transition: transform 0.7s ease-out, -webkit-transform 0.7s ease-out;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}
.paymentrecord .singlePaymentRecord .singlePaymentRecordActions button i {
  font-size: 16px;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  vertical-align: middle;
}
.paymentrecord .singlePaymentRecord .singlePaymentRecordActions button:hover i {
  -webkit-transform: scale(1.5);
  -ms-transform: scale(1.5);
  transform: scale(1.5);
}
.paymentrecord .totalPayed {
  text-align: end;
  padding: 10px 0;
  font-weight: 700;
  color: #157347;
}
/* ====================== END ====================== */

/* =================Email modal START =============== */
/* .emailDataContainer {
  display: flex;
}
.emailDataContainer {
} */

/* ==================== END =========================== */

/* ==============Preview In Create Invoice Start ============ */
.previewInvoiceContainer {
  background-color: #ffff;
  padding: 15px 25px;
  -webkit-box-shadow: 0 0px 10px rgb(0 0 0 / 20%);
  box-shadow: 0 0px 10px rgb(0 0 0 / 20%);
  -webkit-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
  font-size: 0.8rem;
}
.salarypreviewcontainer {
  background-color: #ffff;
  padding: 25px;
  -webkit-box-shadow: 0 0px 10px rgb(0 0 0 / 20%);
  box-shadow: 0 0px 10px rgb(0 0 0 / 20%);
  -webkit-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
  font-size: 0.8rem;
}
.previewInvoiceContainer .previewHeader .FromHeader {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.previewInvoiceContainer .previewHeader .FromHeader .fromDetails {
  text-align: left;
  padding: 10px 0;
}
.previewInvoiceContainer .previewHeader .FromHeader .invoiceDetails {
  text-align: end;
  font-size: 14px;
}
.previewInvoiceContainer .previewHeader .toDetailsContainer {
  text-align: start;
  border-top: 1px solid #ddd;
  margin: 10px 0;
  padding: 10px 0;
}
.previewInvoiceContainer .previewHeader .toDetailsContainer .toDetailsHeader {
  text-align: left;
}
.previewInvoiceContainer .previewHeader .toDetailsContainer .toDetailsHeader .toDetailsHeading {
  font-weight: 600;
}
.previewBodyDesktop {
  margin: 5px 0;
}
.previewBodyDesktop .previewBodytableDesktop .previewBodytableheadDesktop {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 10px 0;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}
.previewBodyDesktop .previewBodytableDesktop .previewBodytableheadDesktop span {
  font-weight: 600;
}
.previewBodyDesktop .previewBodytableDesktop .previewBodytableheadDesktop {
  text-align: center;
}
.previewBodyDesktop .previewBodytableDesktop .previewBodytableheadDesktop .descHead {
  text-align: start;
}
.previewBodytableDesktop .previewBodyTableBodyContainer .previewSinglerecord {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  padding: 10px 0;
  border-bottom: 1px dashed rgb(191, 191, 191);
  word-break: break-word;
  /* text-transform: capitalize; */
}
.previewBodyDesktop .previewBodytableDesktop .salarytotalAmount-container .amount-in-words{text-align: left;text-transform: capitalize;}
.previewBodytableDesktop .previewBodyTableBodyContainer .previewSinglerecord .additionalDesc p {
  font-size: 13px;
}
.responsive-quantity {text-align: center!important;margin: 0 auto;width: max-content!important;}
.responsive-quantity  .responsive-multiple{display: none;}

.previewBodytableDesktop .previewBodyTableBodyContainer .previewSinglerecord div:nth-child(1) {
  text-align: start;
}
.previewBodytableDesktop .previewBodyTableBodyContainer .previewSinglerecord .descriptionlabel {
  font-weight: 600;
}
.previewBodytableDesktop p {
  margin-bottom: 0rem;
}
.previewBodytableDesktop .previewBodyTableFooterContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 10px 0;
}
.previewBodytableDesktop .previewBodyTableFooterContainer .calcCont {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  /* gap: 15px;  */
  padding: 5px 0;
}
.previewBodytableDesktop .previewBodyTableFooterContainer .calcCont .calcContHeading {
  /* font-weight: 600; */
  font-size: 13px;
  text-transform: uppercase;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  display: block;
}
/* .previewBodytableDesktop .previewBodyTableFooterContainer .calcCont .calcContHeading p{

} */
.previewBodytableDesktop .previewBodyTableFooterNotesContainer {
  padding: 10px 0;
}
.previewBodytableDesktop .previewBodyTableFooterNotesContainer .salary-inword{
  text-align: start;text-transform: capitalize;}
.previewBodytableDesktop .previewBodyTableFooterNotesContainer .footerNotes {
  padding: 10px 0;
  text-align: start;
}
/* =============Preview In Create Invoice END============== */
/*===============recordPayment END  =================*/
.sideSetting .taxDisHeader,
.taxationDiscountContainer .taxDisHeader {
  padding: 5px 0;
  text-align: start;
  border-bottom: 1px solid #ddd;
  margin: 10px 0;
  font-weight: 600;
}
.taxationDiscountContainer .taxDisBody .taxDisBodyFileds {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-weight: 600;
  padding: 10px;
}
.taxationDiscountContainer .taxDisBody .taxDisBodyFileds .labelCont {
  text-align: start;
}
.taxationDiscountContainer .taxDisBody .taxDisBodyFileds .formField input {
  width: 100%;
  padding: 5px;
  border: 1px solid #ced4da;
  border-radius: 3px;
  -webkit-transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  -o-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  padding: 0.375rem 0.75rem;
}
.taxationDiscountContainer .taxDisBody .taxDisBodyFileds .formField input:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
  border-radius: 3px;
}
.invoiceFooter {
  padding: 25px 15px;
}
.invoiceFooter div.header {
  padding: 5px 0;
  text-align: start;
  font-weight: 600;
}
.invoiceFooter .notesContainer textarea {
  resize: none;
  border-radius: 0.175rem;
  color: #555;
  /* padding: 5px; */
  /* width: 100%; */
  /* border: unset; */
  /* border-radius: 3px; */
  /* transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out; */
  /* padding: 0.375rem 0.4rem; */
  margin: 0 0 5px 0;
  /* box-shadow: 2px 2px 4px 0 rgb(0, 0, 0, 0.2); */
  /* width: 100%; */
  /* height: 40px; */
  /* padding: 0 0 0 16px; */
  border-radius: 6px;
  outline: none;
  background-color: #f2f2f2;
  border: 1px solid #e5e5e500;
  -webkit-transition: all 0.3s cubic-bezier(0.15, 0.83, 0.66, 1);
  -o-transition: all 0.3s cubic-bezier(0.15, 0.83, 0.66, 1);
  transition: all 0.3s cubic-bezier(0.15, 0.83, 0.66, 1);
  border: 1px solid #ddd;
}

/* ============================================================== */

/* ======================payslip group START====================== */
  .searchCreate-invoice .createInvoice button{
    font-weight: 600;
  }

/* ======================payslip group END====================== */


/* ====Settings  START============*/
.SettingContainer .settingsInnerContainer {
  margin: auto;
  box-shadow: 0 0px 10px rgb(0 0 0 / 20%);
}
.SettingContainer h3 {
  text-align: start;
  font-weight: 600;
}
.SettingContainer h5 {
  /* border-bottom:1px solid #ddd; */
  text-align: start;
  margin-bottom: 0;
  font-weight: 600;
}
.SettingContainer .card {
  padding: 5px;
}
.SettingContainer .card .custom-header {
  background-color: transparent;
}
.SettingContainer .card .formField,
.addEaringsModal .formField {
  /* width: 100%; */
  /* margin: 15px 0; */
  /* display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; */
  padding-block: 5px;
}
.SettingContainer .company-default-notes textarea{
  height: 175px;
}
.SettingContainer .card .formField label,
.addEaringsModal .formField label {
  font-weight: 600;
  text-align: start;
  margin-bottom: 4px;
}
/* .SettingContainer .card .formField input[type="url"],
.SettingContainer .card .formField input[type="email"],
.SettingContainer .card .formField input[type="tel"],
.SettingContainer .card .formField input[type="number"],
.SettingContainer .card .formField input[type="color"], */
.SettingContainer .card .formField input:is([type="text"],[type="url"],[type="email"],[type="tel"],[type="number"],[type="color"]),
.addEaringsModal .formField input {
  /* width: 100%; */
  padding: 5px;
  border: 1px solid #ced4da;
  border-radius: 3px;
  -webkit-transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  -o-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  padding: 0.375rem 0.75rem;
  color: #555;
  padding: 5px 14px;
  /* width: 100%; */
  /* border: unset; */
  /* border-radius: 3px; */
  /* transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out; */
  /* padding: 0.375rem 0.4rem; */
  margin: 0 0 5px 0;
  /* box-shadow: 2px 2px 4px 0 rgb(0, 0, 0, 0.2); */
  /* width: 100%; */
  height: 40px;
  /* padding: 0 0 0 16px; */
  border-radius: 4px;
  outline: none;
  background-color: #f2f2f2;
  border: 1px solid #e5e5e500;
  -webkit-transition: all 0.3s cubic-bezier(0.15, 0.83, 0.66, 1);
  -o-transition: all 0.3s cubic-bezier(0.15, 0.83, 0.66, 1);
  transition: all 0.3s cubic-bezier(0.15, 0.83, 0.66, 1);
}
.SettingContainer .card .taxDisBodyFileds.taxDisBodyFiledsCheckbox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.SettingContainer .card .taxDisBodyFileds.taxDisBodyFiledsCheckbox input[type="checkbox"] {
  vertical-align: middle;
}
.SettingContainer .card .taxDisBodyFileds {
  font-weight: 600;
  padding: 4px;
}
.SettingContainer :is(.earningContainer,.deductionsContainer)
/* .SettingContainer .deductionsContainer  */
{
  box-shadow: 0 0px 10px rgb(0 0 0 / 20%);
}
.SettingContainer .card .taxDisBodyFileds .labelCont {
  text-align: start;
  font-weight: 600;
  text-align: start;
  font-size: 16px;
  margin-bottom: 4px;
}
.SettingContainer .submitContainer {
  text-align: end;
  padding: 10px 45px;
}
/* .SettingContainer .deductionsContainer .card-body .addContainer  */
.SettingContainer :is(.earningContainer,.deductionsContainer) .card-body .addContainer
{
  text-align: end;
}
/* .SettingContainer .deductionsContainer .card-body .addContainer button  */
.SettingContainer :is(.earningContainer,.deductionsContainer) .card-body .addContainer button
{
  font-weight: 600;
}
/* .SettingContainer .deductionsContainer .card-body .deductionListing i  */
.SettingContainer :is(.earningContainer,.deductionsContainer) .card-body :is(.earningListing,.deductionListing) i
{
  cursor: pointer;
}
/* ==END Settings css===*/
.res-tax,.res-tax-note{display: none;}
/* Media Queries */
@media (max-width: 375px) {
  .col-xs-12 {
    width: 100%;
  }
  .invoice-actions .invoice-navBtn .btn {
    width: 100%;
    min-width: -webkit-max-content;
    min-width: -moz-max-content;
    min-width: max-content;
  }
  .invoice-container .invoice-list .invoice-actions .searchCreate-invoice {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .invoice-container .invoice-list .invoice-actions .searchCreate-invoice .createInvoice a.btn {
    width: 100%;
  }
  .invoiceFormContainer .formNavigateContainer button{width: 100%;margin: 6px;}
.invoiceFormContainer .formNavigateContainer {
  display: flex;
  flex-wrap: wrap;
}
}
@media(max-width: 490px) {
  .paymentrecord .singlePaymentRecord {
    /* justify-content: flex-end; */
    flex-wrap: wrap;
    gap: 0;
  }
  .email-wrapper .invoice-actions .search-container{flex-wrap: wrap;gap: 0px!important;}
  .invoice-container .invoice-list, .email-wrapper .invoice-list,.account-setting-wrapper .account-setting-wrapper-inner{width: 90%!important;}
  .account-setting-wrapper .account-setting-wrapper-inner .tabs ul.tablist{gap:unset!important;}
  
.clients-container {
  width: 90%!important;
}

.paymentrecord .singlePaymentRecord .singlePaymentRecordActions{order: 2; width: 100%;justify-content: flex-end;}
.paymentrecord .paymentContainer .paymentContainerInner{display: block;}
.paymentrecord .paymentContainer .paymentContainerInner .dueBalanceContainer{text-align: end;}
}
@media (max-width:575px){.PersonalDetails .formStepOne :is(.fromPersonalDetails,.InvoiceInfo,.toPersonalDetails) .formField,.createGroup-container .fromPersonalDetails .formField, .sideSetting .taxDisBodyFileds {display: block!important;}.sideSetting .taxDisBodyFileds label{margin-bottom:3px!important;padding-left: 10px!important;}}
@media (max-width: 767px) {
  .table-header-custom{display: block;}
  .invoice-actions {
    display: block;
  }
  .res-tax-container{position: relative;}
  .res-col{display: none;}.res-tax,.res-tax-note{display: block;}
  .res-tax-note{text-align: left;}
  .res-tax{position: absolute;
    top: -6px;
    left: calc(100%);}
  .responsive-description{display: block!important;}
  .responsive-divider{position: relative;}
  .responsive-divider::before {
    content: '';
    position: absolute;
    height: 1px;
    background-color: #bfbfbf;
    width: 108%;
    left: -4px;
    top: -2px;
  }
  .responsive-quantity{
    margin: 0 0 0 auto;
    display: flex;    gap: 20px;
    justify-content: space-between;}
  .responsive-quantity  .responsive-multiple{display: block;}
  .responsive-divider::after{content: '';
    position: absolute;
    height: 1px;
    background-color: #bfbfbf;
    width: 108%;
    bottom: -2px;
    left: -4px;
  }
  .invoice-actions .invoice-navBtn .btn {
    width: 33.33%;
    min-width: -webkit-max-content;
    min-width: -moz-max-content;
    min-width: max-content;
  }
  .invoice-actions .invoice-navBtn.invoice-navBtn-custom{
    order: 2;
    width: 100%;
  }
  /* .invoice-container .invoice-list .invoice-actions{
    justify-content: flex-end;
  } */
  .email-wrapper .invoice-actions .search-container{width: 100%!important;}
  .previewInvoiceContainer .previewHeader .FromHeader .invoiceDetails {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border-top: 1px solid #ddd;
    padding: 10px 0;
    text-align: start;
  }
  .previewInvoiceContainer .previewHeader .FromHeader .invoiceDetails > div {
    width: 50%;
  }
  .new-edit-invoice-container .invoiceFormContainer .formStepsContainer .salary-description-table {
    display: block;
  }
  /* .previewInvoiceContainer .previewHeader .FromHeader .fromDetailsContainer {
    order: 2;
  } */
  .previewInvoiceContainer .previewHeader .FromHeader .invoiceDetailsContainer {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .previewInvoiceContainer .previewHeader .FromHeader .invoiceDetails .invoice {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    width: 50%;
  }
  .previewInvoiceContainer .previewHeader .FromHeader .invoiceDetails .balanceDue {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
    text-align: end;
  }
  .previewInvoiceContainer .previewHeader .FromHeader .invoiceDetails .invoiceDate {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }
  .previewInvoiceContainer .previewHeader .FromHeader .invoiceDetails .dueDate {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
    text-align: end;
  }
  .toDetailsContainer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .createitems-container{width:90%!important;}
  .expenses-wrapper .header-wrapper .months-filter-wrapper{flex-wrap: wrap;}
  .expenses-wrapper .header-wrapper .months-filter-wrapper button.btn-month{width: fit-content!important;}
 
}

/* @media(max-width: 991px) and (min-width: 768px){.sideSetting .taxDisBodyFileds{display: flex;}} */


@media (max-width: 991px) {
  .sideSetting .taxDisBodyFileds{display: flex;align-items: center;}
  .sideSetting .taxDisBodyFileds label{ margin-bottom: 0;}
  .invoice-container .invoice-list {
    width: 100%;
  }
  .create-expense-wrapper .expense-form-wrapper .formField{display: block!important;}
 
  /* .invoice-detail .invoice-actions .invoice-actions-export {
    flex-direction: column;
  }
  .invoice-actions .invoice-navBtn{
    flex-direction: column;
  } */
}
/* @media (max-width:1024px){
 .create-expense-wrapper .expense-form-wrapper .formField{display: block!important;}
} */
/*  input Number */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* Input placeholder */
input.form-control::-webkit-input-placeholder,
textarea.form-control::-webkit-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  /* color: #ddd; */
  opacity: 0.4; /* Firefox */
}
input.form-control::-moz-placeholder,
textarea.form-control::-moz-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  /* color: #ddd; */
  opacity: 0.4; /* Firefox */
}
input.form-control:-ms-input-placeholder,
textarea.form-control:-ms-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  /* color: #ddd; */
  opacity: 0.4; /* Firefox */
}
input.form-control::-ms-input-placeholder,
textarea.form-control::-ms-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  /* color: #ddd; */
  opacity: 0.4; /* Firefox */
}
input.form-control::placeholder,
textarea.form-control::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  /* color: #ddd; */
  opacity: 0.4; /* Firefox */
}

input.form-control:-ms-input-placeholder,
textarea.form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  /* color: #ddd; */
  opacity: 0.4;
}

input.form-control::-ms-input-placeholder,
textarea.form-control::-ms-input-placeholder {
  /* Microsoft Edge */
  /* color: #ddd; */
  opacity: 0.4;
}
/* Input placeholder */
.fadeInOpacity {
  -webkit-animation: opac 1s;
  animation: opac 1s;
}

@-webkit-keyframes opac {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes opac {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes shake {
  25% {
    translate: 6px 0;
  }
  50% {
    translate: -6px 0;
  }
  75% {
    translate: -6px 0;
  }
}

/* css added by majid */
.salary-slip-details {
  display: -webkit-box;

  display: -ms-flexbox;

  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.preview-table-footer{
  font-size: 15px;
  font-weight: 700;
  text-align: end;
  margin-top: 6px;
  padding: 5px 0;
  display: flex;
}
.salarypreviewcontainer:has(.total-amount-formdata) .total-amount-formdata{border-top: 1px solid #ddd ;}
.total-amount-formdata{font-size: 15px;
  font-weight: 700;
  text-align: end;
  margin-top: 6px;
  /* border-top: 1px solid #ddd ; */
  padding-top: 10px;
  padding: 10px 0 5px 0;
  margin-bottom: 0;
}
.preview-table-footer div:first-child {text-align: left;}
.personal-details-method {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  font-size: 15px;
  gap: 5px;
}

.heading-customer-name {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 10px;
}
.to-from-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
@media (max-width: 770px) {
  .previewInvoiceContainer .previewHeader .toDetailsContainer {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .previewInvoiceContainer
    .previewHeader
    .toDetailsContainer
    .toDetailsHeader
    .toDetailsHeading {
    margin-top: 10px;
  }
  .responsive-design-preview {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .responsive-design-preview .inner-preview-amount {
    width: 100% !important;
  }
  /* .form-details-image {
    text-align: center !important;
  } */
}
div[data-row-id="1"] {
  font-weight: 700;
}
/* Style for the invoice container */
.invoice-container {
  /* background-color: #f5f5f5; */
  padding: 0 20px;
  /* display: flex;
    align-items: center;
    justify-content: center;
    height: 90vh; */
}

/* Style for the container holding the invoice list */
.container.invoice-list {
  background-color: #fff;
  padding: 15px;
  border-radius: 5px;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  min-height: 600px;
}

/* Style for the invoice actions container */
.invoice-actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  /* margin-bottom: 20px; */
}

/* Style for the invoice navigation buttons */
/* .invoice-navBtn button {
  margin-right: 10px;
} */

/* Style for the create invoice button */
.createInvoice {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  /* -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end; */
  /* width: 150px !important; */
}

/* Style for the invoice list footer */
.invoice-list-footer {
  text-align: center;
  /* margin-top: 20px; */
  color: #666;
}

/* Style for the active navigation button */

/* Style for the invoice list container */
/* .container.px-0 {
  margin-bottom: 20px;
} */

.invoice-container button.active,
.invoice-actions button.active {
  background-color: #baecf4 !important;
  border: 2px solid rgb(0, 164, 189) !important;
  color: #374151 !important;
}

/* Email history css START */
.email-wrapper-size{
  height: 400px;
}
/* .email-wrapper .email-wrapper-inner .single-email-wrapper .single-email-inner{
  
} */
.email-wrapper .invoice-actions .search-container{
  display: flex;
    width: 35%;
    align-items: center;
    gap: 10px;
}
.email-wrapper .invoice-actions .search-container label {
  font-weight: 600;
}
.email-wrapper .email-wrapper-inner .single-email-wrapper  .single-email-inner{
  text-align: start;
  padding:0 7px;
  border-left: 3px solid var(--light-blue);
  margin: 7px 0;
}
.email-wrapper .email-wrapper-inner .single-email-wrapper .single-email-inner .single-email-inner-container p{
  margin: 0;
}

.email-wrapper .email-wrapper-inner .single-email-wrapper .single-email-inner:hover {
  color: rgba(0, 0, 0, 0.87);
  background-color: rgb(238, 238, 238);
  transition-duration: 0.15s;
  transition-property: background-color;
  border-bottom-color: rgb(255, 255, 255);
  outline: rgb(255, 255, 255) solid 1px;
}

.invoice-actions .searchCreate-invoice .email-history{
  display: flex;
  /* gap: 5px; */
  align-items: center;
  justify-content: center;
}
/* Email history css END */


/* Reports CSS START */
.reports-wrapper .reports-container {
  padding: 7px 0;    
  /* min-height: 583px; */
}
.reports-wrapper .report-type-container{display: flex;justify-content: flex-end;}
.invoice-actions .report-type-inner{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 7px;
}
.invoice-actions .report-type-inner{
  font-weight: 600;
}
.reports-wrapper .reports-container .report-header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
  padding: 0 8px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.reports-wrapper .reports-container .reports-body{
  min-height: 400px;
  position: relative;
}
.reports-wrapper .reports-container .report-header .report-header-stats{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-weight: 600;
}

.reports-wrapper .reports-container .reports-body .report-body-stats .report-body-single-stat{
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding: 8px;
}
.reports-wrapper .reports-container .reports-body .report-body-stats .report-body-single-stat:first-child{
  font-weight: 600;

}
.reports-wrapper .reports-container .reports-body .report-body-stats .report-body-single-stat  .report-single-stat-count .single-amount span{
  text-overflow: ellipsis;
  word-break: break-word;
}
.reports-wrapper .reports-container .reports-body .reports-no-result{
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.reports-wrapper .reports-container .reports-body .report-body-stats .report-body-single-stat .report-single-stat-count {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.reports-wrapper .reports-container table{text-align: center;}
.reports-wrapper .reports-container table tr td:first-child,.reports-wrapper .reports-container table tr th:first-child{
  text-align: left;
}
.reports-wrapper .reports-container table tbody tr:first-child{font-weight: 600;}
.reports-wrapper .reports-container .report-body-stats .report-body-single-stat:hover{
  background-color: rgba(0, 0, 0, 0.12);
}
.reports-wrapper .reports-container .reports-footer .years-wrapper{
  padding: 15px 0;
}
:is(.reports-wrapper .reports-container .reports-footer, .footer-wrapper) .year-inner-container
/* .reports-wrapper .reports-container .reports-footer .years-wrapper .year-inner-container,
.footer-wrapper .years-wrapper .year-inner-container */
{
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
    justify-content: flex-end;
}
:is(.reports-wrapper .reports-container .reports-footer, .footer-wrapper) .year-inner-container button.btn{letter-spacing: 1px;}

/* Reports CSS END */


/* -----------------
    Expenses CSS START
-------------------*/

.expenses-wrapper .header-wrapper .action-wrapper{display: flex;justify-content: flex-end;}
.expenses-wrapper .header-wrapper .action-wrapper a.btn{
  font-weight: 600;letter-spacing: 1px;
  display: flex;
  align-items: center;border-radius: 0.25rem;
  gap: 10px;}
.expenses-wrapper .header-wrapper .months-filter-wrapper{    display: flex;    padding: 15px 0;}
/* .expenses-wrapper .months-filter-wrapper button.btn-month{} */
.expenses-wrapper .header-wrapper .months-filter-wrapper button.btn-month:first-child{    
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;}
  .expenses-wrapper .header-wrapper .months-filter-wrapper button.btn-month:last-child{border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;}
.expenses-wrapper .header-wrapper .months-filter-wrapper button.btn-month {
  outline: none;
  border: none;letter-spacing: 1px;
  padding: 10px;
  border: 1px solid #d1d5db;
  background-color: #e5e7eb;
  font-weight: 600;
  font-size: 14px;
    color: #374151;
    width: 100%;
}
.expenses-wrapper .footer-wrapper .total-expense-wrapper{    text-align: end;
  padding: 0 0 10px 0;
  font-size: 15px;}
/* .expenses-wrapper .header-wrapper .months-filter-wrapper button.btn-month.active{} */
.expenses-wrapper .listing-wrapper header{padding: 0;}
.expenses-wrapper .listing-wrapper button{display: flex;gap: 10px;align-items: center;}
.expenses-wrapper .listing-wrapper .table-header-custom{gap: 0;} 
/* create expense */
.create-expense-wrapper .expense-form-wrapper{
  background-color: #fff;
  padding: 20px;
  border-radius: 3px; 
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
.create-expense-wrapper .expense-form-wrapper .formField{display: flex;align-items: center; padding: 5px 0;}
.create-expense-wrapper .expense-form-wrapper .formField label{text-align: left;font-weight: 600;margin: 0 0 5px 2px;}
.create-expense-wrapper .expense-form-wrapper .formField :is(input:is([type="text"],[type="number"],[type="date"]),textarea){
  color: #555;
  padding: 5px 14px;
  margin: 0 0 5px 0;
  border-radius: 4px;
  outline: none;
  background-color: #f2f2f2;
  border: 1px solid #e5e5e500;
  transition: all 0.3s cubic-bezier(0.15, 0.83, 0.66, 1);
}
.create-expense-wrapper .expense-form-wrapper .formField textarea{min-height: 125px;}

.create-expense-wrapper .expense-form-wrapper .formField input:is([type="text"],[type="number"]){height: 40px;}
.create-expense-wrapper .expense-form-wrapper .button-wrapper{display: flex;justify-content: flex-end;}
.create-expense-wrapper .expense-form-wrapper .button-wrapper button{display: flex;align-items: center; gap: 10px;}

/* -----------------
    Expenses CSS END
--------------------*/

/* -----------------
    Contract Listing CSS START
--------------------*/
p.brand-note{text-align: start;}
p.brand-note a{text-decoration: none;color: var(--notification-txt-danger);}
p.brand-note a span{text-decoration: underline;}

/* custom Loader START*/


.table-loader{height: 50px;}
.table-loader svg {
  width: 3.25em;
  transform-origin: center;
  animation: rotate4 2s linear infinite;
 }
 
 .table-loader circle {
  fill: none;
  stroke: hsl(214, 97%, 59%);
  stroke-width: 2;
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  animation: dash4 1.5s ease-in-out infinite;
 }
 
 @keyframes rotate4 {
  100% {
   transform: rotate(360deg);
  }
 }
 
 @keyframes dash4 {
  0% {
   stroke-dasharray: 1, 200;
   stroke-dashoffset: 0;
  }
 
  50% {
   stroke-dasharray: 90, 200;
   stroke-dashoffset: -35px;
  }
 
  100% {
   stroke-dashoffset: -125px;
  }
 }
 
/* @keyframes bouncing-loader {
  to {
    opacity: 0.1;
    transform: translate3d(0, -16px, 0);
  }
}

.bouncing-loader {
  display: flex;
  justify-content: center;
} */

/* .bouncing-loader > div {
  width: 5px;
  height: 5px;
  margin: 3rem 0.1rem;
  background: #8385aa;
  border-radius: 50%;
  animation: bouncing-loader 0.6s infinite alternate;
}

.bouncing-loader > div:nth-child(2) {
  animation-delay: 0.2s;
}

.bouncing-loader > div:nth-child(3) {
  animation-delay: 0.4s;
} */

.custom-loader-container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 10000;
}
.custom-loader {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.custom-loader .spinner {
  width: 67px;
  height: 67px;
  display: -ms-grid;
  display: grid;
  border: 4.5px solid #0000;
  border-radius: 50%;
  border-color: #dbdcef #0000;
  -webkit-animation: spinner-e04l1k 1s infinite linear;
  animation: spinner-e04l1k 1s infinite linear;
}
.custom-loader img {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.custom-loader .spinner::before,
.custom-loader .spinner::after {
  content: "";
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  grid-area: 1/1;
  margin: 2.2px;
  border: inherit;
  border-radius: 50%;
}

.custom-loader .spinner::before {
  border-color: #14164c #0000;
  -webkit-animation: inherit;
  animation: inherit;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  animation-direction: reverse;
}

.custom-loader .spinner::after {
  margin: 8.9px;
}

@-webkit-keyframes spinner-e04l1k {
  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

@keyframes spinner-e04l1k {
  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}
/* custom Loader END*/

/* custom delete loader Start */

.custom-loader-container .lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.custom-loader-container .lds-roller div {
  -webkit-animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  -webkit-transform-origin: 40px 40px;
  -ms-transform-origin: 40px 40px;
  transform-origin: 40px 40px;
}
.custom-loader-container .lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: var(--themeColor);
  margin: -4px 0 0 -4px;
}
.custom-loader-container .lds-roller div:nth-child(1) {
  -webkit-animation-delay: -0.036s;
  animation-delay: -0.036s;
}
.custom-loader-container .lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.custom-loader-container .lds-roller div:nth-child(2) {
  -webkit-animation-delay: -0.072s;
  animation-delay: -0.072s;
}
.custom-loader-container .lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.custom-loader-container .lds-roller div:nth-child(3) {
  -webkit-animation-delay: -0.108s;
  animation-delay: -0.108s;
}
.custom-loader-container .lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.custom-loader-container .lds-roller div:nth-child(4) {
  -webkit-animation-delay: -0.144s;
  animation-delay: -0.144s;
}
.custom-loader-container .lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.custom-loader-container .lds-roller div:nth-child(5) {
  -webkit-animation-delay: -0.18s;
  animation-delay: -0.18s;
}
.custom-loader-container .lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.custom-loader-container .lds-roller div:nth-child(6) {
  -webkit-animation-delay: -0.216s;
  animation-delay: -0.216s;
}
.custom-loader-container .lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.custom-loader-container .lds-roller div:nth-child(7) {
  -webkit-animation-delay: -0.252s;
  animation-delay: -0.252s;
}
.custom-loader-container .lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.custom-loader-container .lds-roller div:nth-child(8) {
  -webkit-animation-delay: -0.288s;
  animation-delay: -0.288s;
}
.custom-loader-container .lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@-webkit-keyframes lds-roller {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes lds-roller {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* custom delete loader END */

/* button custom spinner  START*/
.spinner-container {
  position: relative;
  padding: 0 24px;
}
.spinner {
  font-size: 28px;
  position: relative;
  display: inline-block;
  width: 1em;
  height: 1em;
}

.spinner.center {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.spinner .spinner-blade {
  position: absolute;
  left: 0.4629em;
  bottom: 0;
  width: 0.074em;
  height: 0.2777em;
  border-radius: 0.0555em;
  background-color: transparent;
  -webkit-transform-origin: center -0.2222em;
  -ms-transform-origin: center -0.2222em;
  transform-origin: center -0.2222em;
  -webkit-animation: spinner-fade9234 1s infinite linear;
  animation: spinner-fade9234 1s infinite linear;
}

.spinner .spinner-blade:nth-child(1) {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}

.spinner .spinner-blade:nth-child(2) {
  -webkit-animation-delay: 0.083s;
  animation-delay: 0.083s;
  -webkit-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg);
}

.spinner .spinner-blade:nth-child(3) {
  -webkit-animation-delay: 0.166s;
  animation-delay: 0.166s;
  -webkit-transform: rotate(60deg);
  -ms-transform: rotate(60deg);
  transform: rotate(60deg);
}

.spinner .spinner-blade:nth-child(4) {
  -webkit-animation-delay: 0.249s;
  animation-delay: 0.249s;
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.spinner .spinner-blade:nth-child(5) {
  -webkit-animation-delay: 0.332s;
  animation-delay: 0.332s;
  -webkit-transform: rotate(120deg);
  -ms-transform: rotate(120deg);
  transform: rotate(120deg);
}

.spinner .spinner-blade:nth-child(6) {
  -webkit-animation-delay: 0.415s;
  animation-delay: 0.415s;
  -webkit-transform: rotate(150deg);
  -ms-transform: rotate(150deg);
  transform: rotate(150deg);
}

.spinner .spinner-blade:nth-child(7) {
  -webkit-animation-delay: 0.498s;
  animation-delay: 0.498s;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.spinner .spinner-blade:nth-child(8) {
  -webkit-animation-delay: 0.581s;
  animation-delay: 0.581s;
  -webkit-transform: rotate(210deg);
  -ms-transform: rotate(210deg);
  transform: rotate(210deg);
}

.spinner .spinner-blade:nth-child(9) {
  -webkit-animation-delay: 0.664s;
  animation-delay: 0.664s;
  -webkit-transform: rotate(240deg);
  -ms-transform: rotate(240deg);
  transform: rotate(240deg);
}

.spinner .spinner-blade:nth-child(10) {
  -webkit-animation-delay: 0.747s;
  animation-delay: 0.747s;
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}

.spinner .spinner-blade:nth-child(11) {
  -webkit-animation-delay: 0.83s;
  animation-delay: 0.83s;
  -webkit-transform: rotate(300deg);
  -ms-transform: rotate(300deg);
  transform: rotate(300deg);
}

.spinner .spinner-blade:nth-child(12) {
  -webkit-animation-delay: 0.913s;
  animation-delay: 0.913s;
  -webkit-transform: rotate(330deg);
  -ms-transform: rotate(330deg);
  transform: rotate(330deg);
}

@-webkit-keyframes spinner-fade9234 {
  0% {
    background-color: #fff;
    box-shadow: 0 0 10px black;
  }

  100% {
    background-color: transparent;
  }
}

@keyframes spinner-fade9234 {
  0% {
    background-color: #fff;
    box-shadow: 0 0 10px black;
  }

  100% {
    background-color: transparent;
  }
}

/* button custom spinner END */

.downloading {
  width: 20px;
  height: 100%;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
}

.custom-arrow {
  width: 3px;
  height: 6px;
  position: absolute;
  top: 0;
  left: 50%;
  /* margin-left: -7px; */
  background-color: #fff;
  -webkit-animation-name: downloading;
  -webkit-animation-duration: 1.5s;
  -webkit-animation-iteration-count: infinite;
  animation-name: downloading;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
}

.custom-arrow:after {
  content: '';
  position: absolute;
  display: block;
  top: 100%;
  left: -5px;
  border-top: 6px solid #fff;
  border-left: 7px solid transparent;
  border-right: 6px solid transparent;
}



@-webkit-keyframes downloading {
  0% {
      top: 0;
      opacity: 1;
  }

  50% {
      top: 110%;
      opacity: 0;
  }

  52% {
      top: -110%;
      opacity: 0;
  }

  100% {
      top: 0;
      opacity: 1;
  }
}

@keyframes downloading {
  0% {
      top: 0;
      opacity: 1;
  }

  50% {
      top: 110%;
      opacity: 0;
  }

  52% {
      top: -110%;
      opacity: 0;
  }

  100% {
      top: 0;
      opacity: 1;
  }
}



/* .basic-single{
  width: 250px;
} */
.select__menu{
  color: #242424;
  font-size: 13px;
  scrollbar-width: thin;
}
.select__menu  {
  font-size: 16px;
  /* font-weight: 600; */
  color: #242424;
  text-align: left;
  min-width: 200px!important;
}
.basic-single{
  width: 100%;
}

.select__indicator-separator{display: none;}

/* .template-select-wrapper{
  width : 400px;
} */
.select__control--is-focused:is(:hover,.select__control--is-focused){
  border-color: #86b7fe!important;
    outline: 0!important;
    box-shadow: 0 0 0 .1rem #24b6f770!important;
}