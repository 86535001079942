.input-fields {
    /* display: flex; */
    /* flex-direction: column;
    align-items: center; */
    /* background-color: white; */
    padding: 25px 0;
    width: 80%;
    margin: 0 auto;
    /* border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: 0 0px 10px rgb(0 0 0 / 20%); */
  }
  
  /* .input-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    flex-direction: row;
    gap: 20px;
    width: 75%;
    justify-content: center;
    align-items: center;
    padding: 10px 0px;
  } */
  .input-wrapper p{
    width: 125px;
    font-weight: 700;
    font-size: 17px;
    margin-bottom: 0;
  }
  .input-wrapper small{
    color: var(--dark-blue);
  }
 .heading-profile{
  width: 100%;
  padding: 15px 20px;
  /* background: rgb(0 0 0 / 3%); */
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  box-shadow: 0 0px 10px rgb(0 0 0 / 20%);
  background-color: #fff;
 }
  
  .season_tabs input {
    width: 100%;
    border: none;
    border-radius: 4px;
    padding: 8px;
    font-size: 16px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border: 1px solid lightgrey;
    text-transform: capitalize;
  }
  .season_tabs input[type='email']{
    opacity: 0.6;
    text-transform: none;
  }
  input:focus {
    outline: none;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  }
  .passwordUpdate-container{
    width: 85%;
  }
  .profile-form button[type='submit'] .submitbuttonInnerContainer {
    display: flex;
  }