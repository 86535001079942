.loginView--container {
    background-image: url(../assets/images/auth2.webp);
    background-position: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    /* min-height: 700px; */
    position: relative;
}

.loginView--container .overlay{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: #24b6f71f; */
  background: rgba(255, 255, 255, 0.5);
  background: rgba(7, 37, 100, 0.2);
  /* background: rgba(0,0,0,0.5); */
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}
/* .loginView--container.registerContainer{
  min-height: 700px;
} */
.loginView--container, .loginView-wrapper {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;
    overflow: hidden;
}
.loginView-wrapper .credentials-wrapper {
    height: 100%;
    /* padding: 20px 0 20px 50px ; */
    width: 50%;
    position: relative;
    overflow: hidden;
}
/* 072564   */
.circle-wrapper{
  position: absolute;
  top: 7%;
  left: 15%;
  width: 32px; height:32px;display: flex;align-items: center;justify-content: center;
  background: rgb(7,37,100);
  background: linear-gradient(179deg, rgba(7,37,100,1) 0%, rgba(0,172,238,1) 100%);border-radius: 50%;
  z-index: 7;
}

.loginView--container.registerContainer .corner-circle{
  left: 0;
  right: unset;
  transform: translate(-46%, -30%);
}
.loginView--container.registerContainer .circle-wrapper{
  right:15%;
  left: unset;
}
  .circle-wrapper .circle-in-circle{
    width: 26px;
    height: 26px;
    border-radius: 50%;
    background-color: #fff;
  }
  .corner-circle { 
    position: absolute;
    top: 0;
    right: 0;
    height: 100px;
    width: 100px;
    border-radius: 50%; 
    background-color: #3683B7;
    transform: translate(46%, -30%);
    opacity: 0.6;
    z-index: 7;
  }
  .upperleft-light-circle {
    position: absolute;
    top: 17%;
    left: 5%;
    width: 20px;
    height: 20px;
    background-color: var(--light-blue);
    border-radius: 50%;
    opacity: 0.7;
    z-index: 7;
  }
  .loginView--container.registerContainer .upperleft-light-circle {
    right: 6%;
    left: unset;
  }

.loginView-wrapper .signUp-content .signUp-content-inner .logoContainer{
  width: 10rem;
  z-index: 4;
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loginView-wrapper .credentials-forget-wrapper-inner .logoContainer{
  width: 100%;
  height: 100px;
  /* background-image: url('../assets/images/forgotpassbg.PNG'); */
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
    /* position: absolute;
    top: 57px;
    left: 50%;
    transform: translate(-44%, -50%); */
}
.loginView-wrapper .signUp-content .signUp-content-inner .logoContainer img{
  width: 100%;
  filter: drop-shadow(2px 4px 2px rgba(0,0,0,0.5));
}
.loginView-wrapper {
  height: 85vh;
  z-index: 1;
  width: 70%;
  border-radius: 20px;
  min-height: 600px;
  margin: 20px 0;
}
.loginView-wrapper .credentials-wrapper-inner {
  align-items: center;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  /* background: rgba(0,0,0,.5); */
  background-color: #FFF;
  /* border-radius: 300px 0 0 20px; */
  display: flex;
  height: 100%;
  /* padding: 0 50px; */
  justify-content: center;
  flex-direction: column;
  border-right: 1px solid #f0f8ff82;
  /* color: #fff; */
  color: var(--themeColor);
  /* gap: 50px; */
}
.credentials-wrapper.credentials-forget-wrapper{
  z-index: 3;
}
.loginView-wrapper .credentials-forget-wrapper-inner{
  border-radius: 20px;
  border: unset;
}
.loginView-wrapper .credentials-forget-wrapper-inner {
  /* height: 20vh; */
}
.loginView-wrapper .credentials-forget-wrapper-inner .logoContainer img{
  width: 100%;
}
.loginView-wrapper .credentials-content {
    margin: 0 auto;
    /* max-width: 450px; */
    width: 100%;
    padding: 0 50px;
    position: relative;
}
.loginView-wrapper .credentials-wrapper form {
    width: 100%;
}
.loginView-wrapper .title {
    /* color: #32373ccc;
    color: var(--greyc-32); */
    font: normal normal normal 2.2rem/2.3rem SourceSansPro-SemiBold,sans-serif;
    font-weight: 600;
    margin-bottom: 0;
  }
.loginView-wrapper .signUp-content-inner span{
  font-weight: 600;
}
 span.pos-rel{
  color: #14164c;
  font-weight: 600;
  background-color: #00acee;
    background-image: linear-gradient(45deg, #00acee, #5655d9bf);
    background-size: 100%;
    background-repeat: repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
}
  span.pos-sec{
  color: var(--light-blue);
  font-weight: 400;
}
.pos-rel {
    position: relative;
}
.or-seperator p {
    /* color: #999b9e; */
    /* color: var(--grey-99); */
    font: normal normal normal 16px/24px SourceSansPro-Regular,sans-serif;
    margin: 20px 0;
    text-align: center;
    font-weight: 600;
}
.loginView-wrapper .or-seperator p:after, .loginView-wrapper .or-seperator p:before {
    background-color: #999b9e;
    /* background-color: var(--grey-99); */
    content: "";
    display: block;
    height: 1px;
    position: absolute;
    top: 12px;
    width: calc(25%);
}
.loginView-wrapper .or-seperator p:before {
    left: 0;
}
.loginView-wrapper .or-seperator p:after {
    right: 0;
}
.credentials-content .platform-wrapper button {
  /* font: normal normal normal 16px/20px SourceSansPro-Regular, sans-serif; */
  width: 100%;
  margin-bottom: 10px;
  /* border-radius: 100px; */
  background-color: #eee;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  -webkit-box-shadow: -2px 2px 10px #105d8d33, 2px -2px 10px #105d8d33;
  box-shadow: -2px 2px 10px #105d8d33, 2px -2px 10px #105d8d33;
}
.credentials-content .platform-wrapper button img {
  padding-right: 5px;
}
.credentials-content .platform-wrapper .googleBtnWrapper{
  position: relative;
}
.credentials-content .platform-wrapper .googleBtnWrapper  button.btn.cursor-disable{
  min-height: 45px;
}
.credentials-content .platform-wrapper .googleBtnWrapper #googleSignIn{
  /* position: absolute;
  top: 2px;
  left: 2px;
  width: 100%;
  visibility: hidden; */
}
.credentials-content .platform-wrapper .googleBtnWrapper #googleSignIn > *{
  width: 100%;
}
.credentials-content .platform-wrapper button {
  /* font: normal normal normal 16px/20px SourceSansPro-Regular, sans-serif; */
  width: 100%;
  /* height: 45px; */
  margin-bottom: 10px;
  /* border-radius: 100px; */
  background-color: #eee;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  /* -webkit-box-shadow: -2px 2px 10px #105d8d33, 2px -2px 10px #105d8d33;
  box-shadow: -2px 2px 10px #105d8d33, 2px -2px 10px #105d8d33; */
  font-weight: 700;
  font-size: 17px;
  /* box-shadow: 4px 8px 19px -3px rgba(0,0,0,0.27); */
  box-shadow: 2px 2px 16px  rgba(0,0,0,0.17)!important;
  border: 2px solid var(--light-blue);
  border-radius: 27px;
}
.credentials-content .platform-wrapper .googleBtnWrapper #googleSignIn{
  display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    opacity: 0.00001;
    /* z-index: 99; */
}
.credentials-content .platform-wrapper .googleBtnWrapper button:hover{
  box-shadow: 2px 2px 16px  rgba(0,0,0,0.47)!important;
}
.credentials-content .platform-wrapper .googleBtnWrapper #googleSignIn>div {
  /* align-items: center!important;
  display: flex!important;
  height: 90%!important;
  justify-content: center!important;
  margin: 0!important; */
  /* opacity: 0;*/
  width: 100%; 
  display: flex;
  justify-content: center;
}
.credentials-content .platform-wrapper button:hover {
  color: #000;
}
.credentials-content .platform-wrapper button img {
  height: 22px;
  width: auto;
}

.loginView-wrapper .credentials-content form.form-container {
    width: 100%;
    border-radius: 0.75rem;
    /* background-color: rgba(17, 24, 39, 1); */
    padding: 2rem;
    color: rgba(243, 244, 246, 1);
  }
  
  .loginView-wrapper .credentials-content form  .title {
    text-align: center;
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 700;
  }
  
  .loginView-wrapper .credentials-content form .form {
    margin-top: 1.5rem;
  }
  
  .loginView-wrapper .credentials-content form  .input-group {
    margin: 1.1rem 0;
    font-size: 0.875rem;
    line-height: 1.7rem;
    font-weight: bold;
  }
  /* .loginView-wrapper .credentials-content form  .input-group  input{
    border-bottom: 1px solid #fff!important;
  } */
  .loginView-wrapper .credentials-content form  .input-group i{
    position: absolute;
    right: 8px;
    top: 44%;
    font-size: 15px;
    transform: translate(-50%, -50%);
    cursor: pointer;
    color: var(--themeColor);
    font-weight: bolder;
    /* text-shadow: 0 0 10px black; */
  }
  .loginView-wrapper .credentials-content form  .input-group.register-icon i{
    top: 70%;
  }
  
  /* .loginView-wrapper .credentials-content form  .input-group label {
    display: block;
    color: #000;
    margin-bottom: 7px;
    font-size: 1rem;
    font-weight: 600;
    color: var(--greyc-32);
  } */
  .loginView-wrapper .credentials-content form .input-group label{display: flex; align-items: center;margin-bottom: 0.2rem;font-size: 16px;    line-height: 1.5;}  
  .loginView-wrapper .credentials-content form .input-group label i {padding-right: 5px; position: relative;top: unset;right: unset;transform: translate(0);}

  .loginView-wrapper .credentials-content form .input-group input, .loginView-wrapper .credentials-content form .input-group select {
    width: 100%;
    border-radius: 3px !important;
    border: 1px solid rgba(55, 65, 81, 1);
    outline: 0;
    padding: 0.75rem 1rem;
    /* color: rgba(243, 244, 246, 1); */
    background-color: #fff;
    border: unset;
    /* border-bottom: 1px solid var(--grey-99); */
    font: normal normal normal 17px/20px SourceSansPro-Regular,sans-serif;
    height: 45px;
    outline: none;
    padding: 6px 16px;
    width: 100%;
    border: 1px solid var(--themeColor);
    transition: all 0.2s ease-in-out;
  }
  
  .loginView-wrapper .credentials-content form .input-group input:focus {
    border-color: var(--light-blue);
  }
  
  .loginView-wrapper .credentials-content form .forgot {
    display: flex;
    justify-content: flex-end;
    font-size: 0.75rem;
    line-height: 1rem;
    color: rgba(156, 163, 175,1);
    margin: 12px 0 14px 0;
  }
  
  .loginView-wrapper .credentials-content form .forgot a, .loginView-wrapper .signup a {
    color: var(--login-achor-text);
    /* text-decoration: none; */
    font-size: 14px;
    font-weight: 600;
  }
  
  .loginView-wrapper .credentials-content form .forgot a:hover, .signup a:hover {
    /* text-decoration: underline #fff; */
    color: var(--light-blue);

  }
  
  .loginView-wrapper .credentials-content form .sign {
    display: block;
    width: 70%;
    background-color: var(--light-blue);
    padding: 0.75rem;
    text-align: center;
    color: #fff;
    border: none;
    border-radius: 0.375rem;
    font-weight: 600;
    margin: 1rem auto;
    transition: all 0.3s ease-in-out;
  }
  .loginView-wrapper .credentials-content form .sign:hover {
    box-shadow: 4px 8px 19px -3px rgba(0,0,0,0.27);
  }
  .loginView-wrapper .credentials-content .social-message {
    display: flex;
    align-items: center;
    padding-top: 1rem;
  }
  
  .loginView-wrapper .credentials-content  .line {
    height: 1px;
    flex: 1 1 0%;
    background-color: rgba(55, 65, 81, 1);
  }
  
  .loginView-wrapper .credentials-content  .social-message .message {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    font-size: 1.1rem;
    line-height: 1.25rem;
    color: #000;
    margin-bottom: 0;
  }
  
  .loginView-wrapper .credentials-content .social-icons {
    display: flex;
    justify-content: center;
  }
  
  .loginView-wrapper .credentials-content .social-icons .icon {
    border-radius: 0.125rem;
    padding: 0.75rem;
    border: none;
    background-color: transparent;
    margin-left: 8px;
  }
  
  .loginView-wrapper .credentials-content  .social-icons .icon svg {
    height: 1.25rem;
    width: 1.25rem;
    fill: #fff;
  }
  
  .loginView-wrapper .credentials-content form .signup {
    text-align: center;
    font-size: 0.75rem;
    line-height: 1rem;
    /* color: #fff; */
    font-weight: 500;
  }
  



/* ========================================== */
.loginView-wrapper .signUp-content {
  /* background-image: linear-gradient(to right bottom,rgba(81,152,208,.5),rgba(81,152,208,.5)),url(../assets/images/auth1.webp); */
    /* background-image: linear-gradient(to right bottom,rgba(81,152,208,.5),rgba(81,152,208,.5)); */
    background-position: 80%;
    background-size: cover;
    height: 100%;
    /* padding: 20px 50px 20px 0; */
    width: 50%;
}
.loginView-wrapper .signUp-content-inner {
    /* backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(10px); */
    background: hsla(0,0%,93%,.8);
    /* border-radius: 0 20px 300px 0; */
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    padding: 5px 50px 0;
    position: relative;
    z-index: 1;
    align-items: center;
}
.loginView-wrapper .signUp-content-inner .span{
  font-weight: 600;
}
.loginView-wrapper .signUp-content-inner .title{
  font-size: 1.8rem;
  line-height: 1.9rem;
}
.loginView-wrapper .signUp-content .sign-up-cta {
    /* border: 2px solid #32373c;
    border: 2px solid var(--grey-32); */
    border-radius: 100px;
    margin-top: 30px;
    max-width: 350px;
    min-width: 200px;
    text-align: center;
}

.loginView-wrapper .signUp-content .sign-up-cta a {
    color: #32373c;
    color: var(--grey-32);
    display: block;
    font: normal normal normal 14px/18px SourceSansPro-SemiBold,sans-serif;
    padding: 10px;
    width: 100%;
    text-decoration: none;
}




/* ====================== */






/* ===========Registeration page ======= */
.loginView--container .input-container{position: relative;margin: 20px 0;}
.loginView--container .input-container .group {
  position: relative;
 }
 
 .loginView--container .input-container .input {
  font-size: 16px;
  padding: 5px 10px 5px 5px;
  border-radius: 3px;
  display: block;
  width: 100%;
  border: none;
  border-bottom: 1px solid var(--themeColor);
  background: transparent;
  border-top: 1px solid #fff;
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
  /* color: #fff; */
 }
 .loginView--container .input-container .input[type="email"], .loginView--container .input-container .input[type="email"]:focus {background-color: transparent!important;}

 .loginView--container .input-container .input:focus{
  border: 1px solid var(--light-blue);
  border-radius: 4px;
 }
 .loginView--container .input-container input:-internal-autofill-selected{background-color: transparent!important;}
 .loginView--container .input-container select.input{
  /* color:#fff ; */
 }
 
 /* .loginView--container  .loginView-wrapper .credentials-content input.input-container{

 } */
 .loginView--container .input-container select.input option {
  color: #000;
}
 .loginView--container .input-container .input:focus {
  outline: none;
  box-shadow: none;
 }
 .input-container-tele .custom-label-telephone{
  /* position: relative!important; */
    top: 7px!important;
    left: 70px!important;
    /* width: 100%;
    text-align: left; */
 }
 .input-container-tele .custom-label{
  position: relative!important;
    top: unset!important;
    left: unset!important;
    width: 100%;
    text-align: left;
    /* color: var(--light-blue); */
 }
 .loginView--container .input-container label {
  /* color: #999; */
  font-size: 1rem;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 6px;
  top: 10px;
  font-weight: 600;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
 }
 .input-container.input-container-tele  .react-tel-input input{ 
  background: transparent;
  border-top: 1px solid #fff;
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
  border-radius: unset;
  border-bottom: 1px solid var(--themeColor);
  transition: all 0.1s ease-in-out;
 }
 .input-container.input-container-tele  .react-tel-input input:focus{box-shadow: none;
  border: 1px solid var(--light-blue);
  border-radius: 4px;
}
 .input-container.input-container-tele  .react-tel-input .country-list .country.highlight{
  position: relative;
    width: 100%;
    opacity: 1;
 }
 .input-container.input-container-tele  .react-tel-input .flag-dropdown{
  background: transparent;
  border: 0;
  border-right: 1px solid var(--themeColor);
 } 
 .input-container .input:focus ~ label,.loginView--container .input:valid ~ label {
  top: -10px;
  font-size: 14px;
  color: var(--light-blue);
  background-color: #fff;
  padding: 0 3px;
 }
 .loginView--container .input-container .react-tel-input input:valid ~ label{
  top: -10px;
  font-size: 14px;
  color: var(--light-blue);
  background-color: #fff;
  padding: 0 3px;
 } 
 
 .loginView--container .input-container .bar {
  position: relative;
  display: block;
  width: 200px;
 }
 
 .loginView--container .input-container  .bar:before,.loginView--container  .bar:after {
  content: '';
  height: 2px;
  width: 0;
  bottom: 1px;
  position: absolute;
  background: #5264AE;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
 }
 
 .loginView--container .input-container  .bar:before {
  left: 50%;
 }
 
 .loginView--container .input-container .bar:after {
  right: 50%;
 }
 
 .loginView--container .input-container .input:focus ~ .bar:before, .input:focus ~ .bar:after {
  width: 50%;
 }
 
 .loginView--container .input-container .highlight {
  position: absolute;
  height: 60%;
  width: 100px;
  top: 25%;
  left: 0;
  pointer-events: none;
  opacity: 0.5;
 }
 
 .loginView--container .input-container .input:focus ~ .highlight {
  animation: inputHighlighter 0.3s ease;
 }
 
 @keyframes inputHighlighter {
  from {
   background: #5264AE;
  }
 
  to {
   width: 0;
   background: transparent;
  }
 }
 .loginView-wrapper .credentials-content form  .input-container i{
  position: absolute;
  right: 8px;
  top: 52%;
  font-size: larger;
  transform: translate(-50%, -50%);
  cursor: pointer;
  /* text-shadow: 0 0 10px black; */
}
.loginView-wrapper .credentials-content form  .input-container.register-icon i{
  position: absolute;
  right: 9px;
  top: 52%;
  font-size: larger;
  transform: translate(28%,-48%);
  cursor: pointer;}

  .loginView-wrapper .credentials-content form  .policyContainer{
    display: flex;
    gap: 20px;
    align-items: center;
  }
  .loginView-wrapper .credentials-content form  .policyContainer a {
    color: var(--dark-blue);
  }
  .loginView-wrapper .credentials-content form  .policyContainer a:hover {
    color: var(--themeColor);
  }
/* ============forget password ============*/

.loginView-wrapper .credentials-wrapper .subscribe {
  position: relative;
  /* height: 140px; */
  /* width: 240px; */
  padding: 20px 0;
  /* background-color: #FFF;s */
  border-radius: 4px;
  /* color: #fff;
  box-shadow: 0px 0px 60px 5px rgba(0, 0, 0, 0.4); */
}
.credentials-wrapper.credentials-forget-wrapper .credentials-wrapper-inner.credentials-forget-wrapper-inner .credentials-content{
  margin: 0 auto;
  /* max-width: 450px; */
  width: 100%;
  padding: 0!important;
  position: relative;
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loginView-wrapper .credentials-wrapper.credentials-forget-wrapper .credentials-wrapper-inner.credentials-forget-wrapper-inner{
justify-content: flex-start;
overflow: hidden;
background-image: url('../assets/images/forgotpassbg.PNG');
background-repeat: no-repeat;
background-position: top;
background-size: contain;
}
.credentials-wrapper-inner.credentials-forget-wrapper-inner .logoContainer.forgetLogoCont img{
  width: auto;
  height: 60%;
  margin-top: 20px;
}
/* 
.loginView-wrapper .credentials-wrapper .subscribe:after {
  position: absolute;
  content: "";
  right: -10px;
  bottom: 18px;
  width: 0;
  height: 0;
  border-left: 0px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid var(--light-blue);
} */
/* .loginView-wrapper .credentials-wrapper .subscribe:hover:after {
  position: absolute;
  content: "";
  right: -10px;
  bottom: 18px;
  width: 0;
  height: 0;
  border-left: 0px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid var(--dark-blue);
} */

.loginView-wrapper .credentials-wrapper .subscribe p {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 4px;
  line-height: 28px;
}

.loginView-wrapper .credentials-wrapper .subscribe input {
  /* position: absolute; */
  bottom: 30px;
  border: none;
  border-bottom: 1px solid var(--themeColor);
  padding: 10px;
  width: 100%;
  background: transparent;
  transition: all .25s ease;
  border-radius: 0;
}

.loginView-wrapper .credentials-wrapper .subscribe input:focus {
  outline: none;
  border: 1px solid var(--light-blue);
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', 'sans-serif';
}

.loginView-wrapper .credentials-wrapper .subscribe .submit-btn {
  /* position: absolute; */
  border-radius: 30px;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  background-color: var(--light-blue);
  color: #FFF;
  padding: 12px 25px;
  display: inline-block;
  font-size: 12px;
  font-weight: bold;
  /* letter-spacing: 5px; */
  /* right: -10px;
  bottom: -20px; */
  cursor: pointer;
  transition: all .25s ease;
  box-shadow: -5px 6px 20px 0px rgba(26, 26, 26, 0.4);
}

.loginView-wrapper .credentials-wrapper .subscribe .submit-btn:hover {
  background-color: var(--dark-blue);
  box-shadow: -5px 6px 20px 0px rgba(88, 88, 88, 0.569);
}
/* .loginView-wrapper .credentials-wrapper .forgetLogoCont{
  top: 107px;
    left: 52%;
    z-index: 4;
    transform: translate(-50%, -50%);
} */
  @media(min-width: 550px){
    .input-container.input-container-tele  .react-tel-input .country-list {
      width: 450px;
    }
  }


  @media(max-width:550px){
    .loginView--container .loginView-wrapper{
      width: 90%!important;
    }
  }

  @media (max-width:767px){
    /* .loginView--container {
      min-height: 700px;
    } */
    .loginView--container .loginView-wrapper{
      width: 80%;
    }
  }

  @media (max-width: 991px){
      .loginView-wrapper .signUp-content{
          display: none;
      }
      /* .loginView--container {
        min-height: 700px;
      } */
      .loginView-wrapper .credentials-wrapper {
        height: 100%;
        width: 100%;
        /* padding: 20px 0 20px 0; */
        padding: unset;
    }
    .loginView-wrapper .credentials-wrapper-inner{
      /* border-radius: 200px 20px; */
      border-radius: unset;
    }
    /* .loginView-wrapper .credentials-wrapper .logoContainer {
      
      left: 50%;
     
  } */
  }
  @media (max-width:1100px) {
    .loginView-wrapper{
      width: 85%;
    }
    
  }
  @media (min-width:1400px) {
    .loginView-wrapper .credentials-wrapper .logoContainer {
      width: 15rem;
      /* left: 35%; */
      z-index: 4;
      /* transform: translate(-50%, -50%); */
  }
  }

  .loginView-wrapper .signUp-content .sign-up-cta a,
  .loginView-wrapper .credentials-wrapper .subscribe .submit-btn,
  .loginView-wrapper .credentials-content form .sign {
    padding: 9px 20px;
    border: 2px solid var(--light-blue);
    border-radius: 0.375rem;
    /* color: #212121; */
    z-index: 1;
    background: #e8e8e8;
    position: relative;
    font-weight: 700;
    font-size: 17px;
    /* -webkit-box-shadow: 4px 8px 19px -3px rgba(0,0,0,0.27); */
    /* box-shadow: 4px 8px 19px -3px rgba(0,0,0,0.27); */
    box-shadow: 2px 2px 3px  rgba(0,0,0,0.17);
    --webkit-box-shadow: 2px 2px 3px  rgba(0,0,0,0.17);
    transition: all 250ms;
    overflow: hidden;
    color: #000;
    width: 100%;
    border-radius: 27px!important;
    background-color: #fff;
   }
   .loginView-wrapper .credentials-content form button.sign:disabled{
    color: #bbb7b7;
    cursor: not-allowed;
   }
   .loginView-wrapper .credentials-content form button.sign:disabled:hover{
    background-color: #fff;
   }
   .loginView-wrapper .credentials-content form button.sign:disabled:hover:before{
    width: 0;
    background-color: #fff;
   }
   .loginView-wrapper .signUp-content .sign-up-cta a::before,
   .loginView-wrapper .credentials-wrapper .subscribe .submit-btn:before,
   .loginView-wrapper .credentials-content form .sign:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 0;
    border-radius: 0.375rem;
    background-color: #14164c  ;/*14164c     var(--light-blue)*/
    z-index: -1;
    -webkit-box-shadow: 4px 8px 19px -3px rgba(0,0,0,0.27);
    box-shadow: 4px 8px 19px -3px rgba(0,0,0,0.27);
    transition: all 250ms
   }
   
   .loginView-wrapper .signUp-content .sign-up-cta a:hover ,
   .loginView-wrapper .credentials-wrapper .subscribe .submit-btn:hover,
   .loginView-wrapper .credentials-content form .sign:hover{
    color: #e8e8e8;
    /* background-color: var(--dark-blue); */
    background-color: var(--themeColor);
    /* box-shadow: -5px 6px 20px 0px rgba(88, 88, 88, 0.569); */
    box-shadow: 2px 2px 16px rgba(0,0,0,0.47);
    border: 2px solid var(--themeColor);
   }
   
   .loginView-wrapper .signUp-content .sign-up-cta a:hover::before,
   .loginView-wrapper .credentials-wrapper .subscribe .submit-btn:hover::before,
   .loginView-wrapper .credentials-content form .sign:hover::before  {
    width: 100%;
   }