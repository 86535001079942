.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 260px;
    background-color: #ffff;
    z-index: 101;
    border-right: 1px solid #CECCCC;
    transition: all 0.5s ease;
    box-shadow: 0.2rem 0 0.5rem rgba(0, 0, 0, 0.1);
  }
  .sidebar.close {
    width: 80px;
  }
  .sidebar .logo-details {
    height: 65px;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 10px;
    /* margin-top: 20px;*/
  }
  .sidebar .logo-details i {
    font-size: 30px;
    color: #77619e;
    height: 50px;
    min-width: 78px;
    text-align: center;
    line-height: 50px;
  }
  
  .sidebar .logo-details .logo_name {
    font-size: 22px;
    color: #77619e;
    font-weight: 600;
    transition: 0.3s ease;
    transition-delay: 0.1s;
    width: 100%;
    height: 100%;
  }
  .sidebar .logo-details .logo_name a {
    height: 100%;
    display: inline-block;
  }
  .sidebar .logo-details .logo_name img{
    width: 100%;
    height: 100%;
  }
  /* .sidebar.close .logo-details .logo_name {
    transition-delay: 0s;
    opacity: 0;
    pointer-events: none;
  } */
  .sidebar .nav-links {
    height: 100%;
    padding: 50px 0 150px 0;
    overflow: auto;
  }
  .sidebar.close .nav-links {
    overflow: visible;
  }
  .sidebar .nav-links::-webkit-scrollbar {
    display: none;
  }
  .sidebar .nav-links li {
    position: relative;
    list-style: none;
    transition: all 0.4s ease;
  }
  .sidebar .nav-links li:hover {
    background: #F8F9FA;
  }
  .sidebar .nav-links li:hover svg path{
    fill: var(--themeColor);
  }
  .sidebar .sub-menu li:hover {
    background: none;
    color: var(--light-blue);
  }
  .sidebar .nav-links li .icon-link {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .sidebar.close .nav-links li .icon-link {
    display: block;
  }
  .sidebar .nav-links li i {
    height: 50px;
    min-width: 78px;
    text-align: center;
    line-height: 50px;
    color: #77619e;
    font-size: 20px;
    cursor: pointer;
    transition: all 0.3s ease;
  }

  .sidebar .nav-links li svg{
    min-width: 78px;
  }
  .sidebar .nav-links li.showMenu i.arrow {
    transform: rotate(-180deg);
  }
  .sidebar.close .nav-links i.arrow {
    display: none;
  }
  .sidebar .nav-links li .nav-link {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    text-decoration: none;
    height: 65px;
    cursor: pointer;
  }
  .sidebar .nav-links li .nav-link.active{
    background: #F8F9FA!important;
    color: var(--light-blue)!important;
    font-weight: 600;
  }
  .sidebar .nav-links li .nav-link.active svg path{
    fill: var(--light-blue);
  }
  .sidebar .nav-links .nav-item:has(.active){
    background: #F8F9FA;
    color: var(--light-blue);
  }
  .sidebar .nav-links .nav-item:has(.active) .nav-link .link_name{
    color: var(--light-blue);
  }
  .sidebar .nav-links .nav-item:has(.active) svg path{
    fill: var(--light-blue);
  }
  .sidebar .nav-links li .nav-link .link_name {
    font-size: 10px;
    font-weight: 600;
    transition: all 0.4s ease;
    padding-top: 4px;
}
.sidebar .nav-links li:hover .nav-link.active .link_name{
    color: var(--light-blue);
}
.sidebar .nav-links li:hover .nav-link .link_name{
    color: var(--themeColor);
  }
  /* .sidebar.close .nav-links li .nav-link .link_name {
    opacity: 0;
    pointer-events: none;
  } */
  .sidebar .nav-links li .sub-menu {
    padding: 6px 6px 14px 80px;
    margin-top: 0px;
    background: #fff;
    display: none;
  }
  .sidebar .nav-links li.showMenu .sub-menu {
    display: block;
  }
  .sidebar .nav-links li .sub-menu a {
        color: #000;
    /* font-size: 16px; */
    padding: 5px 15px;
    white-space: nowrap;
    /* opacity: 0.6; */
    transition: all 0.3s ease;
    height: 40px;
    align-items: flex-start;
    font-weight: 600;
  }
  .sidebar .nav-links li .sub-menu a:hover {
    opacity: 1;
    background: #F8F9FA;
    color: var(--themeColor);
  }
  .sidebar.close .nav-links li .sub-menu {
    position: absolute;
    left: 100%;
    top: -10px;
    margin-top: 0;
    padding: 0;
    border-radius: 0 6px 6px 0;
    opacity: 0;
    display: block;
    pointer-events: none;
    transition: 0s;
    min-width: 109px;
  }
  .sidebar.close .nav-links li:hover .sub-menu {
    top: 0;
    opacity: 1;
    pointer-events: auto;
    transition: all 0.4s ease;
    -webkit-box-shadow: 4px 3px 10px rgb(20 22 76 / 20%);
    box-shadow:4px 3px 10px rgb(20 22 76 / 20%);
  }
  .sidebar .nav-links li .sub-menu .link_name {
    display: none;
  }
  .sidebar.close .nav-links li .sub-menu .link_name {
    /* font-size: 18px; */
    opacity: 1;
    display: flex;
    /* border-bottom: 1px solid #ddd; */
  }

  .sidebar.close .nav-links li .sub-menu .link_name  {
    border: unset;
    border-bottom: 1px solid #ddd;
  }
  .sidebar .nav-links li .sub-menu.blank {
    opacity: 1;
    pointer-events: auto;
    /* padding: 3px 20px 6px 16px; */
    opacity: 0;
    pointer-events: none;
  }
  .sidebar .nav-links li:hover .sub-menu.blank {
    top: 50%;
    transform: translateY(-50%);
  }
  .sidebar .profile-details {
    position: fixed;
    bottom: 0;
    width: 260px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #F8F9FA;
    padding: 12px 0;
    transition: all 0.5s ease;
  }
  .sidebar.close .profile-details {
    background: none;
  }
  .sidebar.close .profile-details {
    width: 77px;
    height: 100px;
  }
  /* .sidebar.close .profile-details  */
  .sidebar .profile-details .profile-content {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 50px;
    cursor: pointer;
    width: 100%;
}
.sidebar .profile-details .profile-content .link_name{
  font-size: 10px;
    font-weight: 600;
    transition: all 0.4s ease;
    padding-top: 4px;
}
.sidebar .profile-details .profile-content:hover{
    background: #F8F9FA;
    color: var(--themeColor);
}
.sidebar .profile-details .profile-content:hover svg path{
    fill: var(--themeColor);
}
  .sidebar .profile-details img {
    height: 52px;
    width: 52px;
    object-fit: cover;
    border-radius: 16px;
    margin: 0 14px 0 12px;
    background: #1d1b31;
  }
  
  .sidebar .profile-details .profile_name,
  .sidebar .profile-details .job {
    color: #77619e;
    font-size: 18px;
    font-weight: 500;
    white-space: nowrap;
    transition: all 5s ease;
  }
  .sidebar.close .profile-details i,
  .sidebar.close .profile-details .profile_name,
  .sidebar.close .profile-details .job {
    display: none;
  }
  .sidebar .profile-details .job {
    font-size: 12px;
  }
  .home-section {
    position: relative;
    background: #e4e9f7;
    height: 100vh;
    left: 260px;
    width: calc(100% - 260px);
    transition: all 0.5s ease;
  }
  .sidebar.close ~ .home-section {
    left: 78px;
    width: calc(100% - 78px);
  }



  @media (max-width: 400px) {
    .sidebar.close .nav-links li .sub-menu {
      display: none;
    }
    .sidebar {
      width: 78px;
    }
    .sidebar.close {
      width: 0;
    }
    .home-section {
      left: 78px;
      width: calc(100% - 78px);
      z-index: 100;
    }
    .sidebar.close ~ .home-section {
      width: 100%;
      left: 0;
    }
  }